import EventTarget from "events";
import {DelegatedEventTarget} from "./delegated_event_target"
import {Helper} from "./helper";
import {AnimType, Character, CustomEvent, PathsType, signs} from "./index";
import {Chapter} from "./chapter";
import {Scene} from "./scene";

export class VideoWatcher extends DelegatedEventTarget {
  readonly fps: number = 30;
  readonly rewardSignTimecodes: PathsType = {
    "happy": "0:7:16",
    "wow": "0:42:19",
    "friend": "0:16:23",
    "cat": "1:18:00",
    "number": "00:15:21",
    "love": "00:59:20",
    "communicate": "00:14:14",
    "surprise": "01:03:22",
    "quick": "00:36:02",
    "birthday": "00:45:03",
    "burst_into_tears": "00:03:00",
    "family": "00:53:10",
  }

  video: HTMLElement;
  videoAsHTMLVideoElement: HTMLVideoElement;
  chapterLabel: number;
  anims: AnimType;
  lotties: PathsType;
  rewardSigns: string[] = [];
  rewardSignsSeen: boolean[] = [false, false];
  handleRewardSignFrame : any;

  constructor(
    anims: AnimType,
    lotties: PathsType,
    handleRewardSignFrame: any
  ) {
    super();

    this.anims = anims;
    this.lotties = lotties;
    this.handleRewardSignFrame = handleRewardSignFrame;
  }

  public setInfo = (
    chapter: Chapter,
    scene: Scene,
    char: Character
  ): any => {
    this.chapterLabel = chapter.chapterLabel;
    this.video = scene.elem;
    this.videoAsHTMLVideoElement = this.video as HTMLVideoElement;
    this.video.addEventListener("timeupdate", this.videoProgress);
    this.video.addEventListener("ended", this.videoStopped);
    this.rewardSigns[0] = signs[char][this.chapterLabel][1];
    this.rewardSigns[1] = signs[char][this.chapterLabel][2];
    console.log("Rewards are: ", this.rewardSigns);
  }

  public reset = () => {
    this.clearListeners();
    this.clearRewardSignsSeen();
    this.clearRewardSigns();
  }

  clearRewardSignsSeen = () => {
    for (let idx in this.rewardSignsSeen) {
      this.rewardSignsSeen[idx] = false;
    }
  }

  clearRewardSigns = () => {
    for (let idx in this.rewardSigns) {
      this.rewardSigns[idx] = null;
    }
  }

  clearListeners = () => {
    if (this.video) {
      this.video.removeEventListener("timeupdate", this.videoProgress);
      this.video.removeEventListener("ended", this.videoStopped);
      this.video.removeEventListener("pause", this.videoStopped);
    }
  }

  videoProgress = () => {
    for (let index in this.rewardSigns) {
      let sign = this.rewardSigns[index];
//      console.log(`this.rewardSignsSeen[index]: ${this.rewardSignsSeen[index]}`);
      let timeCode: number = Helper.timecodeToTime(this.rewardSignTimecodes[sign]);
      if (
        !this.rewardSignsSeen[index] &&
        sign in this.rewardSignTimecodes &&
        this.videoAsHTMLVideoElement.currentTime > Helper.timecodeToTime(this.rewardSignTimecodes[sign])
      ) {
        this.rewardSignsSeen[index] = true;
        this.handleRewardSignFrame(parseInt(index), sign)
      }
    }
  }

  videoStopped = (event: Event) => {
    if (this.anims["reward"]) this.anims["reward"].destroy();
    this.video.removeEventListener("timeupdate", this.videoProgress);
    this.video.removeEventListener("ended", this.videoStopped);
  }

  public getCurrentTime = () => {
    return this.videoAsHTMLVideoElement.currentTime;
  }

  public setCurrentTime = (time: number) => {
    this.videoAsHTMLVideoElement.currentTime = time;
  }
}
