import EventTarget from "events";
import { DelegatedEventTarget } from "./delegated_event_target";

export class GestureWatcher extends DelegatedEventTarget {
  lastSeenPred: Date = new Date();
  waitBeforePredicting: number = 2000; // milliseconds
  labels = ["birthday",
    "cat",
    "communicate",
    "burst_into_tears",
    "emma",
    "family",
    "friend",
    "happy",
    "love",
    "number",
    "quick",
    "ravi",
    "surprise",
    "wow"];
  videoElement: HTMLVideoElement;
  buffers: any = [];
  handsConstr: any;
  cameraConstr: any;
  drawConnectors: any;
  drawLandmarks: any;
  handConnections: any;
  document: any;
  model: any;
  hands: any;
  camera: any;

  public onPrediction?: (label: string) => void

  constructor(
    handsConstr: any,
    cameraConstr: any,
    drawConnectors: any,
    drawLandmarks: any,
    handConnections: any,
    document: any,
    tf: any
  ) {
    super();

    this.videoElement = document.getElementsByClassName('input-video')[0];
    this.handsConstr = handsConstr;
    this.cameraConstr = cameraConstr;
    this.drawConnectors = drawConnectors;
    this.drawLandmarks = drawLandmarks;
    this.handConnections = handConnections;
    this.document = document;
    this.model = tf.loadGraphModel("model/model.json")
    // var model = tf.loadGraphModel("https://silverbrane-web-production.s3-ap-southeast-2.amazonaws.com/ohPh3gap/model.json")
  }

  private _prediction: Event = new Event("prediction")

  public loadResources = (): void => {
    this.model.then((promise_value: any) => {
      this.model = promise_value;
      this.dispatchEvent(
        new CustomEvent("model-loaded")
      )
    }).catch(function(e: any) {
      console.log("model error: ", e);
    });

    let filesLoaded: number = 0;

    // @ts-ignore
    this.hands = new this.handsConstr({
      locateFile: (file: any) => {
        filesLoaded += 1;
        if (filesLoaded == 5) {
          this.dispatchEvent(
            new CustomEvent("hands-loaded")
          );
        }
        return `https://cdn.jsdelivr.net/npm/@mediapipe/hands@0.3.1620080774/${file}`;
      }
    });

    this.hands.onResults(this.onResults);

    this.camera = new this.cameraConstr(this.videoElement, {
      onFrame: async () => {
        await this.hands.send({ image: this.videoElement });
      },
      width: 1280,
      height: 720
    });

    // a dummy send to force mediapipe to load assets
    this.hands.send({});
  }

  public startCamera = (): void => {
    console.log("start camera");
    this.camera.start();
  }

  onResults = (results: any) => {
    const feature_offsets = [0, 42, 84]
    const num_features = 86
    const buffer_size = 30

    // @ts-ignore 
    let buffer_view = tf.buffer([1, 1, num_features])
    if (results.multiHandLandmarks && results.multiHandedness) {
      for (let index = 0; index < results.multiHandLandmarks.length; index++) {
        let i = index
        const classification = results.multiHandedness[index];
        const isRightHand = classification.label === 'Right';
        const landmarks = results.multiHandLandmarks[index];


        // 0 for left-hand; 1 for right-hand
        let label_id = classification.index
        // FIXME What if the hand classification is wrong and predicts two
        //       left hands? This would overwrite existing values.
        const offset = feature_offsets[label_id];

        // fill feature tensor with  landmarks
        for (let j = 0; j < 21; ++j) {
          buffer_view.set(landmarks[j].x, 0, 0, offset + 2 * j)
          buffer_view.set(landmarks[j].y, 0, 0, offset + 2 * j + 1)
        }

        // Set "present" flag for hand
        buffer_view.set(1.0, 0, 0, feature_offsets[2] + label_id)
      }
    }
    this.buffers.push(buffer_view.toTensor())

    if (this.buffers.length > buffer_size) {
      this.buffers.shift()
    } else if (this.buffers.length < buffer_size) {
      return
    }

    // @ts-ignore 
    let input = tf.concat(this.buffers, 1)
    let prediction = this.model.predict(input)
    let floatArray = prediction.dataSync()

    if (floatArray[0] > 0.6) {
      //      this.document.querySelector("#results").innerHTML = "__background__ " + floatArray[0]
      //      this.document.querySelector("#results").innerHTML = "__background__ "
    }
    else {
      // FIXME use tf.argmax
      var max = -10000
      var maxIndex = 0
      for (var i = 0; i < floatArray.length - 1; i++) {
        if (floatArray[i + 1] > max) {
          max = floatArray[i + 1]
          maxIndex = i
        }
      }
      var label = this.labels[maxIndex]
      let timeSinceLastPred: number = (new Date()).getTime() - this.lastSeenPred.getTime();
      if (timeSinceLastPred > this.waitBeforePredicting) {
        this.dispatchEvent(
          new CustomEvent("prediction", { detail: { label: label } })
        );
        this.lastSeenPred = new Date();
      }
      if (label !== "background") {
        //        this.document.querySelector("#results").innerHTML = label;
      }
    }
  }
}
