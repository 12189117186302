import {
  anims, AnimType, audioTimeMarkers, Character, CustomEvent,
  mainSpeed, sceneAudioVolumes, SceneSummary
} from "./index";
import {AudioPlayer} from "./audio_player";

export class Helper {
  public static hide = (element: HTMLElement): void => {
    element.classList.add("hide");
  }

  public static show = (element: HTMLElement): void => {
    element.classList.remove("hide");
  }

  public static addDisplayFlex = (element: HTMLElement): void => {
    element.classList.add("display-flex");
  }

  public static removeDisplayFlex = (element: HTMLElement): void => {
    element.classList.remove("display-flex");
  }

  public static addClass = (element: HTMLElement, className: string): void => {
    element.classList.add(className);
  }

  public static removeClass = (element: HTMLElement, className: string): void => {
    element.classList.remove(className);
  }

  public static ensureSceneBorder = (element: HTMLElement, char: Character = null): void => {
    if (char === null) char = Character.Emma;
    element.classList.remove("border-video-Emma");
    element.classList.remove("border-video-Ravi");
    element.classList.add(`border-video-${Character[char]}`);
  }

  public static ensureSceneBkgd = (storyScenes: SceneSummary[], element: HTMLElement, storyScene: SceneSummary): void => {
    // remove all possible previously applied background class names
    for (let idx in storyScenes) {
      if (!storyScenes[idx].backgroundColour) continue;
      let className: string = `bkgd-${storyScenes[idx].backgroundColour}`;
      if (element.classList.contains(className)) {
        element.classList.remove(className);
      }
    }
    // add current one
    element.classList.add(`bkgd-${storyScene.backgroundColour}`);
  }

  public static labelFromEvent = (
    customEvent: any,
    keyName: string
  ): string => {
    return customEvent.detail[keyName];
  }

  /* Assumes timecode has mm:ss:ms format. Ms are ignored */
  public static timecodeToTime = (timecode: string): number => {
    let segments = timecode.split(":");
    let seconds = 0;
    if (segments[0]) {
      seconds += 60*(+segments[0]);
    }
    if (segments[1]) {
      seconds += +segments[1];
    }
    return seconds;
  }

  public static currentTimeToFrame = (
    currentTime: number,
    fps: number
  ) => {
    return Math.floor(currentTime * fps);
  }

  public static loadAnimation = (
    bodymovin: any,
    elementId: string,
    path: string,
    autoplay: boolean = true,
    loop: boolean = false,
    goToAndPlay: number = null,
    initialSegment: number[] = null,
    speed: number
  ) => {
    try {
      var animation = bodymovin.loadAnimation({
        container: document.getElementById(elementId),
        name: elementId,
        renderer: 'svg',
        loop: loop,
        autoplay: autoplay,
        path: path
      });

      if (initialSegment !== null) {
        animation.initialSegment = initialSegment
      }

      if (goToAndPlay !== null) {
        animation.goToAndPlay(goToAndPlay, true);
      }

      animation.setSpeed(speed);

      return animation;
    } catch(e) {
      console.log(`ERROR loading animation: ${e}`);
    }
  }

  public static clearAnims = (anims: AnimType) => {
    let keys = Object.keys(anims);
    if (keys.length == 0) return;
    for (let key of keys) {
      anims[key].destroy();
    }
    anims = {};
  }

  public static getCurrentFrame(currentTime: number, totalTime: number, totalFrames: number) {
    return Math.floor((currentTime / totalTime) * totalFrames);
  }

  public static playAudioAfterTimeout = (audioPlayer: AudioPlayer, audioName: string): number => {
    return window.setTimeout(() => {
      audioPlayer.setAndPlayAudio(audioName, false);
    }, (audioTimeMarkers[audioName] / mainSpeed));
  }

  public static getPlaybackVolume = (name: string) => {
    let volume: number;
    if (name in sceneAudioVolumes) {
      volume = sceneAudioVolumes[name];
    } else {
      volume = 0.5;
    }
    console.log(`Volume ${volume} set for audio named ${name}`)
    return volume;
  }
}
