import "./style.scss"
import * as tf from "@tensorflow/tfjs";
import * as bodymovin from "lottie-web";
import { GestureWatcher } from "./gesture_watcher";
import { VideoWatcher } from "./video_watcher";
import { Analytics } from "./analytics";
import EventTarget from "events";
import { Helper } from "./helper";
import { Scene } from "./scene";
import { Chapter } from "./chapter";
import { AudioPlayer } from "./audio_player";
import { Animation } from "./animation";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

/* INTERFACES */
declare global {
  interface Window {
    observerService: any;
    digitalData: object;
    emma: any;
    ravi: any;
  }
}

interface TransitionsType {
  [key: string]: string;
}

export interface PathsType {
  [key: string]: string;
}

export interface NumType {
  [key: string]: number;
}

export interface SignsDoneType {
  [key: string]: boolean;
}

export type VideoPathType = {
  [key in Character]: {
    [key: number]: string;
  }
}

interface StoryFramesType {
  [key: string]: SceneFramesType;
}

type StoryIndexFramesType = {
  [key in Character]: CharIdxFramesType;
}

interface CharIdxFramesType {
  [key: number]: SceneFramesType;
}

interface SceneFramesType {
  [key: string]: [number, number];
}

type StoryRewardsShownType = {
  [key in string]: boolean;
}

interface ChapterMarkerType {
  [key: string]: SceneFunction;
}

type StorySignsType = {
  [key in Character]: CharSignType;
}

interface CharSignType {
  [key: number]: ChapterSignType;
}

interface ChapterSignType {
  [key: number]: string;
}

interface SceneRewardsType {
  [key: number]: string;
}

export interface AnimType {
  [key: string]: Lottie.AnimationItem;
}

interface AudioType {
  [key: string]: HTMLAudioElement;
}

export interface CustomEvent extends Event {
  detail: string
}

interface SceneFunction {
  (event?: Event): void;
}

export interface SceneSummary {
  character?: Character,
  chapterLabel?: number,
  changeSceneBorder: boolean,
  type: SceneType,
  backgroundColour?: string,
  sceneFunction?: any,
  nextSceneFunction?: any,
  analyticsInteraction?: string,
  videoTime?: number,
  visited?: boolean,
  chapterPart?: string,
}

/* ENUMS */
enum Character {
  Ravi = 0,
  Emma
}
export { Character };

export enum SceneType {
  Video = 0,
  Animation
}

/* CONSTANTS */
declare const drawConnectors: any;
declare const HAND_CONNECTIONS: any;
declare const drawLandmarks: any;
declare const Hands: any;
declare const Camera: any;

/* VERSION */
const version: string = "1.0.7";

/* LOTTIES */
const lotties: PathsType = {
  "switch_ravi": "lottie/overlays/ravi.json",
  "switch_emma": "lottie/overlays/emma.json",
  "switch_ravi_help": "lottie/ravi_help.json",
  "switch_emma_help": "lottie/emma_help.json",
  "happy": "lottie/overlays/happy.json",
  "happy_rollover": "lottie/overlays/rollover/happy.json",
  "happy_reward": "lottie/rewards/happy.json",
  "wow": "lottie/overlays/wow.json",
  "wow_rollover": "lottie/overlays/rollover/wow.json",
  "wow_reward": "lottie/rewards/wow.json",
  "number": "lottie/poc/communicate.json",
  "number_reward": "lottie/poc/communicate-rewards.json",
  "love": "lottie/poc/communicate.json",
  "love_reward": "lottie/poc/communicate-rewards.json",
  "communicate": "lottie/poc/communicate.json",
  "communicate_reward": "lottie/poc/communicate-rewards.json",
  "surprise": "lottie/poc/communicate.json",
  "surprise_reward": "lottie/poc/communicate-rewards.json",
  "friend": "lottie/overlays/friend.json",
  "friend_rollover": "lottie/overlays/rollover/friend.json",
  "friend_reward": "lottie/rewards/friend.json",
  "cat": "lottie/overlays/cat.json",
  "cat_rollover": "lottie/overlays/rollover/cat.json",
  "cat_reward": "lottie/rewards/cat.json",
  "quick": "lottie/poc/communicate.json",
  "quick_reward": "lottie/poc/communicate-rewards.json",
  "birthday": "lottie/poc/communicate.json",
  "birthday_reward": "lottie/poc/communicate-rewards.json",
  "burst_into_tears": "lottie/poc/communicate.json",
  "burst_into_tears_reward": "lottie/poc/communicate-rewards.json",
  "family": "lottie/poc/communicate.json",
  "family_reward": "lottie/poc/communicate-rewards.json",
  "intro_1": "lottie/intro/sh30_introCombined_BB_Part1.json",
  "intro_2": "lottie/intro/sh30_introCombined_BB_Part2.json",
  "intro_3": "lottie/intro/sh30_introCombined_BB_Part3.json",
  "intro_combined_1": "lottie/intro/sh30_introCombined_BB_All_3.json",
  "intro_combined_2": "lottie/intro/SBS_Intro_explainer_a01_MCS.json",
  "landing": "lottie/landing_page_2Title.json",
  "scene1": "lottie/1Loader.json",
  "scene2": "lottie/2Title.json",
  "scene3": "lottie/3Permissions.json",
  "scene4": "lottie/4Hi.json",
  "scene4-emma-rollover": "lottie/4Hi_rollover_Emma_sign.json",
  "scene4-ravi-rollover": "lottie/4Hi_rollover_Ravi_sign.json",
  "scene4-choose": "lottie/4Hi_nowchoose.json",
  "scene4-emma-transit": "lottie/4Hi_Emmatransit.json",
  "scene4-ravi-transit": "lottie/4Hi_Ravitransit.json",
  "wave_emma": "lottie/wave_emma.json",
  "wave_ravi": "lottie/wave_ravi.json",
  "emma_reward": "lottie/4Chapter1emma_tick.json",
  "ravi_reward": "lottie/4chapter1Ravi_tick.json",
  "left_practice_reward": "lottie/6Chapter1tick_left.json",
  "right_practice_reward": "lottie/6Chapter1tick_right.json",
  "ravi_practice_cat": "lottie/6Chapter1Ravi_Cat_Rollover.json",
  "ravi_practice_friend": "lottie/6Chapter1Ravi_Friend_Rollover.json",
  "welldone_colours": "lottie/welldone_colours.json",
  "try_start": "lottie/try/start.json",
  "info": "lottie/info.json",
  "end": "lottie/end.json",
  "incompatible": "lottie/incompatible.json",
}

/* SIGNS */
export const signs: StorySignsType = {
  [Character.Emma]: {
    1: {
      1: "happy",
      2: "wow",
    },
    2: {
      1: "number",
      2: "love",
    },
    3: {
      1: "communicate",
      2: "surprise",
    },
  },
  [Character.Ravi]: {
    1: {
      1: "friend",
      2: "cat",
    },
    2: {
      1: "quick",
      2: "birthday",
    },
    3: {
      1: "burst_into_tears",
      2: "family",
    },
  }
}

export const audioPaths: PathsType = {
  // scenes
  "scene2": "audio/scenes/intro.m4a",
  "chapter-title": "audio/scenes/chapter_title.m4a",

  // chapters
  "Emma-chapter1": "audio/chapters/emma1.m4a",
  "Emma-chapter2": "audio/chapters/emma2.m4a",
  "Emma-chapter3": "audio/chapters/emma3.m4a",
  "Ravi-chapter1": "audio/chapters/ravi1.m4a",
  "Ravi-chapter2": "audio/chapters/ravi2.m4a",
  "Ravi-chapter3": "audio/chapters/ravi3.m4a",

  // rewards
  "happy": "audio/rewards/happy.m4a",
  "wow": "audio/rewards/wow.m4a",
  "friend": "audio/rewards/friend.m4a",
  "cat": "audio/rewards/cat.m4a",
  "birthday": "audio/rewards/birthday.m4a",
  "burst_into_tears": "audio/rewards/burst_into_tears.m4a",
  "communicate": "audio/rewards/communicate.m4a",
  "family": "audio/rewards/family.m4a",
  "quick": "audio/rewards/quick.m4a",
  "love": "audio/rewards/love.m4a",
  "number": "audio/rewards/number.m4a",
  "surprise": "audio/rewards/surprise.m4a",

  // effects
  "effects-loader1": "audio/effects/loader1.m4a",
  "effects-loader2": "audio/effects/loader2.m4a",
  "effects-loader3": "audio/effects/loader3.m4a",
  "effects-loader4": "audio/effects/loader1.m4a",
  "effects-loader5": "audio/effects/loader2.m4a",
  "effects-loader6": "audio/effects/loader3.m4a",
  "effects-loader7": "audio/effects/loader1.m4a",
  "effects-loader8": "audio/effects/loader2.m4a",
  "effects-loader9": "audio/effects/loader1.m4a",
  "effects-loader10": "audio/effects/loader2.m4a",
  "effects-loader11": "audio/effects/loader1.m4a",
  "effects-permissions1": "audio/effects/permissions1.mp3",
  "effects-permissions3": "audio/effects/permissions3.m4a",
  "effects-permissions7": "audio/effects/permissions7.mp3",
  "effects-permissions8": "audio/effects/permissions8.mp3",
  "effects-permissions9": "audio/effects/permissions9.m4a",
  "effects-permissions10": "audio/effects/permissions9.m4a",
  "effects-permissions11": "audio/effects/permissions11.m4a",
  "effects-permissions12": "audio/effects/permissions11.m4a",
  "effects-permissions12a": "audio/effects/permissions9.m4a",
  "effects-permissions12b": "audio/effects/permissions11.m4a",
  "effects-permissions13": "audio/effects/permissions13.mp3",
  "effects-permissions15": "audio/effects/permissions15.mp3",
  "effects-permissions16": "audio/effects/permissions16.mp3",
  "effects-permissions17": "audio/effects/permissions9.m4a",
  "effects-permissions18": "audio/effects/permissions11.m4a",
  "effects-permissions19": "audio/effects/permissions9.m4a",
  "effects-permissions20": "audio/effects/permissions11.m4a",
  "effects-practice1": "audio/effects/practice1.m4a",
}

const audioTimeMarkers: NumType = {
  "effects-permissions9": 4700,
  "effects-permissions10": 7800,
  "effects-permissions11": 10450,
  "effects-permissions12": 13950,
  "effects-permissions12a": 18300,
  "effects-permissions12b": 18850,
  "effects-permissions17": 5500,
  "effects-permissions18": 7400,
  "effects-permissions19": 2100,
  "effects-permissions20": 2500,
  "effects-loader1": 10,
  "effects-loader2": 50,
  "effects-loader3": 300,
  "effects-loader4": 1000,
  "effects-loader5": 2900,
  "effects-loader6": 3000,
  "effects-loader7": 3500,
  "effects-loader8": 4800,
  "effects-loader9": 5700,
  "effects-loader10": 6000,
  "effects-loader11": 6600,
}
export { audioTimeMarkers };

const errorMsgs: PathsType = {
  "errMsgInsuffUserMedia": "Your webcam doesn't have sufficient resolution to interact with this documentary. Please try another webcam.",
  "errMsgNoWebcam": "Your computer doesn't have a webcam, this is required for this documentary. Please try again on a computer that has a webcam.",
  "errMsgDeclinedPermsVideo": "You have declined camera permissions - you will need to enable your webcam to continue. Please clear your browser's camera permissions settings for this website, then refresh this page to try again.",
  "errMsgDeclinedPermsVideoLater": "You have declined to continue - you will need to confirm that you are happy to use your webcam to use this site. This page will now reload so you can try again.",
  "alreadyGivenPermsMsg": "Just a reminder - this site uses your webcam so that you can participate by learning select signs in this story. No videos will be recorded or stored. Is that ok?",
  "generalErrorMsg": "We're very sorry, but an error has ocurred. Once you dismiss this message, the page will reload so you can try again. For testing purposes, if you see this error while your browser console is open you will see an error message like 'General error caught' that you can send to us to help fix it."
}

const transitions: TransitionsType = {
  "scene-1": "scene-2",
  "scene-2": "scene-3",
}

/* SCENES */
const scenes: StoryFramesType = {
  // overlays
  "ravi": {
    "bit1": [1, 64],
    "bit2": [64, 141],
  },
  "emma": {
    "bit1": [1, 82],
    "bit2": [82, 154],
  },
  "happy": {
    "bit1": [1, 209],
    "bit2": [209, 209],
  },
  "wow": {
    "bit1": [1, 257],
    "bit2": [257, 257],
  },
  "friend": {
    "bit1": [1, 233],
    "bit2": [233, 233],
  },
  "cat": {
    "bit1": [1, 222],
    "bit2": [222, 222],
  },
  "number": {
    "bit1": [1, 253],
    "bit2": [253, 253],
  },
  "love": {
    "bit1": [1, 205],
    "bit2": [205, 205],
  },
  "quick": {
    "bit1": [1, 224],
    "bit2": [224, 224],
  },
  "birthday": {
    "bit1": [1, 221],
    "bit2": [221, 221],
  },
  "communicate": {
    "bit1": [1, 287],
    "bit2": [287, 287],
  },
  "surprise": {
    "bit1": [1, 221],
    "bit2": [221, 221],
  },
  "burst_into_tears": {
    "bit1": [1, 235],
    "bit2": [235, 235],
  },
  "family": {
    "bit1": [1, 169],
    "bit2": [169, 169],
  },

  // scenes
  "scene1": {
    "bit1": [1, 264],
  },
  "scene2": {
    "bit1": [1, 186],
    "bit2": [186, 394],
    "bit3": [394, 420],
  },
  "scene3": {
    "bit1": [1, 360],
    "bit2": [407, 467],
    "bit3": [491, 618],
  },
  "scene4": {
    "bit1": [1, 90],
    "bit2": [90, 550],
    "bit3": [550, 640],
    "bit4": [640, 662],
    "restarting": [971, 1348],
  },
  "scene6": {
    "bit1": [1, 399],
    "bit2": [411, 435],
  },
  "scene8": {
    "bit1": [1, 453],
    "bit2": [453, 503],
  },
  "well_done": {
    "bit1": [41, 101],
  }
}

const practiceFrames: StoryIndexFramesType = {
  [Character.Emma]: {
    1: {
      "bit1": [1, 399],
      "bit2": [411, 435],
    },
    2: {
      "bit1": [1, 280],
      "bit2": [294, 314],
    },
    3: {
      "bit1": [1, 403],
      "bit2": [417, 435],
    },
  },
  [Character.Ravi]: {
    1: {
      "bit1": [1, 309],
      "bit2": [326, 346],
    },
    2: {
      "bit1": [1, 258],
      "bit2": [275, 294],
    },
    3: {
      "bit1": [1, 263],
      "bit2": [278, 298],
    },
  }
}

/* VIDEOS */
export const videos: VideoPathType = {
  [Character.Emma]: {
    1: "video/emma1_small.mp4",
    2: "video/emma2_small.mp4",
    3: "video/emma3_small.mp4"
  },
  [Character.Ravi]: {
    1: "video/ravi1_small.mp4",
    2: "video/ravi2_small.mp4",
    3: "video/ravi3_small.mp4"
  }
}

export const sceneAudioVolumes: NumType = {
  "scene2": 0.2,
  "effects-permissions7": 0.3,
  "chapter_title": 0.2,
  "Emma-chapter1": 0.3,
  "Emma-chapter2": 0.3,
  "Emma-chapter3": 0.3,
  "Ravi-chapter1": 0.3,
  "Ravi-chapter2": 0.3,
  "Ravi-chapter3": 0.3,
  "happy": 0.2,
  "wow": 0.4,
  "number": 0.9,
  "love": 0.5,
  "surprise": 0.4,
  "communicate": 0.6,
  "friend": 0.2,
  "cat": 0.4,
  "quick": 0.5,
  "birthday": 0.3,
  "burst_into_tears": 0.4,
  "family": 0.4,
}

/* SPEED */
const mainSpeed: number = 1;
export { mainSpeed };
const rewardSignSpeed: number = 1;
const numTimesPlayRewards: number = 2;

/* ELEMENT NAMES */
const emmaSwitcherElem = "anim-switch-emma-sign";
const raviSwitcherElem = "anim-switch-ravi-sign";

/* ELEMENTS */
const scene_1 = document.getElementById('scene-1');
const scene_2 = document.getElementById('scene-2');
const scene = document.getElementById("scene-1");
const sceneInside = document.getElementById("scene-inside");
const landingButton = document.getElementById("landing-button");
const scene2Button = document.getElementById("scene2-button");
const helpButtonWithContents = document.getElementById("help-button-contents");
const fullScreenButton = document.getElementById("full-screen-button");
const fullScreenEscapeButton = document.getElementById("full-screen-escape-button");
const muteButton = document.getElementById("mute-button");
const unmuteButton = document.getElementById("unmute-button");
const help = document.getElementById("help");
const helpClose = document.getElementById("help-close");
const readAboutClose = document.getElementById("read-about-close");
const outputCanvas = document.getElementById("output-canvas")
const wellDone = document.getElementById("well-done")
const scene4Button1 = document.getElementById("scene4-button-1")
const scene4Reward1 = document.getElementById("scene4-reward-1");
const scene4Reward2 = document.getElementById("scene4-reward-2");
const animReward = document.getElementById("anim-reward")
const sceneElement = document.getElementById("scene-1")
export { sceneElement };
const mainAnimElem1 = document.getElementById("anim-main-1");
export { mainAnimElem1 };
const mainAnimElem2 = document.getElementById("anim-main-2");
export { mainAnimElem2 };
const scene6Button1 = document.getElementById("scene6-button-1");
const scene6Button2 = document.getElementById("scene6-button-2");
const scene6Button3 = document.getElementById("scene6-button-3");
const scene6Reward1 = document.getElementById("scene6-reward-1");
const scene6Reward2 = document.getElementById("scene6-reward-2");
const switchEmmaSign = document.getElementById(emmaSwitcherElem);
const switchRaviSign = document.getElementById(raviSwitcherElem);
const switchButton = document.getElementById("anim-switch-button");
const docElem = document.documentElement;
const fps: number = 29.97;
const chapterNav = document.getElementById("chapter-nav");
const animPracticeReward = document.getElementById("anim-practice-reward");
const oldBrowserContainer = document.getElementById("old-browser-container");
let video1 = document.getElementById("video-player-1");
export { video1 };
let video2 = document.getElementById("video-player-2");
export { video2 };
let video1AsHTMLVideoElement = video1 as HTMLVideoElement;
let video2AsHTMLVideoElement = video2 as HTMLVideoElement;
let webcamVideoContainer = document.getElementById("webcam-video-container");
let webcamVideo = document.getElementById("webcam-video");
let openWebcamViewer = document.getElementById("open-webcam-viewer");
let closeWebcamViewer = document.getElementById("close-webcam-viewer");
let animChPrDemo = document.getElementById("anim-ch-prac-demo");
let animationRewardRollover1Button = document.getElementById("anim-rwrd-rllvr-1-button");
let animationRewardRollover2Button = document.getElementById("anim-rwrd-rllvr-2-button");
let wellDoneStartLink = document.getElementById("well-done-start");
let wellDoneReadAboutLink = document.getElementById("well-done-read-about");
let wellDoneCreditsLink = document.getElementById("well-done-credits");
let infoAnimElem = document.getElementById("anim-info");
let tryContainer = document.getElementById("try-container");
let endNav = document.getElementById("end-nav");
let endNavSee = document.getElementById("end-nav-see");
let endNavStart = document.getElementById("end-nav-start");
let endNavTry = document.getElementById("end-nav-try");
let tryNav = document.getElementById("try-nav");
let tryNavSee = document.getElementById("try-nav-see");
let tryNavStart = document.getElementById("try-nav-start");
let tryNavTry = document.getElementById("try-nav-try");
let helpNav = document.getElementById("help-nav");
let helpBrRm = document.getElementById("help-brows-room");
let helpCredits = document.getElementById("help-credits");
let helpLinkIntro = document.getElementById("help-link-intro");
let helpLinkRavi1 = document.getElementById("help-link-ravi-1");
let helpLinkEmma1 = document.getElementById("help-link-emma-1");
let helpLinkRavi2 = document.getElementById("help-link-ravi-2");
let helpLinkEmma2 = document.getElementById("help-link-emma-2");
let helpLinkRavi3 = document.getElementById("help-link-ravi-3");
let helpLinkEmma3 = document.getElementById("help-link-emma-3");
let helpLinkEnd = document.getElementById("help-link-end");
let helpLinkTry = document.getElementById("help-link-try");
let helpSwitchRavi = document.getElementById("help-switch-ravi");
let helpSwitchEmma = document.getElementById("help-switch-emma");
let rewardSign1Elem = document.getElementById("anim-reward-1-sign");
let rewardSign2Elem = document.getElementById("anim-reward-2-sign");
let wellDoneScore = document.getElementById("well-done-score");
let charRollover1 = document.getElementById("anim-char-rllvr-1")
let charRollover2 = document.getElementById("anim-char-rllvr-2")
let charRolloverButton1 = document.getElementById("anim-char-rllvr-1-button")
let charRolloverButton2 = document.getElementById("anim-char-rllvr-2-button")
let buttonMouseoverAnimation = document.getElementById("anim-button-mouseover");
let buttonMousedownAnimation = document.getElementById("anim-button-mousedown");
let readAbout = document.getElementById("read-about");
let scene4ChooseRaviButton = document.getElementById("scene4-choose-ravi-button");
let scene4ChooseEmmaButton = document.getElementById("scene4-choose-emma-button");
let helpShowCamera = document.querySelector("#help-show-camera");
let incompatibleText = document.getElementById("incompatible-text");
let tooSmallText = document.getElementById("too-small-text");
let loadingIcon = document.getElementById("loading-icon");

/* VARIABLES */
let anims: AnimType = {};
export { anims };
let mainAnim: Animation;
let audios: AudioType = {};
let predType: string;
let gestureWatcher: GestureWatcher;
let videoWatcher: VideoWatcher;
let analytics: Analytics;
let globalRewardSigns: string[] = [];
let globalRewards: string[] = [];
let globalRewardIsPlaying: boolean = false;
let practiceRewardIsPlaying: boolean = false;
let previousChapter: Chapter;
let currentChapter: Chapter;
let previousScene: Scene;
let currentScene: Scene = new Scene(SceneType.Animation, null);
let mainAudioPlayer: AudioPlayer = new AudioPlayer();
export { mainAudioPlayer };
let effectsAudioPlayer1: AudioPlayer = new AudioPlayer();
let effectsAudioPlayer2: AudioPlayer = new AudioPlayer();
let sbsLogo = document.getElementById("sbs-logo");
let buttons = document.getElementById("buttons");
let checkedVidPerms: boolean;
let isInFullScreen: boolean = false;
let isMuted: boolean = false;
let previousStoryScene: SceneSummary;
let currentStoryScene: SceneSummary;
let storedStoryScene: SceneSummary;
let infoAnim: any;

/* START */
let practicePredictionHandler: any;
let chooseRaviEmmaHandler: any;
let globalStoryRewardsShown: StoryRewardsShownType = {
  "happy": false,
  "wow": false,
  "number": false,
  "love": false,
  "surprise": false,
  "communicate": false,
  "friend": false,
  "cat": false,
  "quick": false,
  "birthday": false,
  "burst_into_tears": false,
  "family": false,
};
let webcamViewerShowing: boolean = false;
let webcamStream: MediaProvider;
let isLoaded: boolean = false;
let rewSignSetupReplay: any;
let practiceHandler: any;
let videoHandler: any;
let elemRewardContnr: HTMLElement;
let helpAnimRavi: any;
let helpAnimEmma: any;
let removePracticeCompleteHnldr: any;
let countSignsDone: SignsDoneType = {
  "happy": false,
  "wow": false,
  "number": false,
  "love": false,
  "surprise": false,
  "communicate": false,
  "friend": false,
  "cat": false,
  "quick": false,
  "birthday": false,
  "burst_into_tears": false,
  "family": false,
};
let jumpToCharacterSelectDueToRestart: boolean = false;
let webcamViewerOpenWhenOpeningHelp: boolean = false;
var detect = require('feature-detect-es6')
let supportsES6: boolean;
let supportsWebGL2: boolean;
let screenBigEnough: boolean;
let screenBigEnoughBeforeResize: boolean = false
let scene4Timeout1: number;
let scene4Timeout2: number;
let scene4Timeout3: number;
let helpOpenedHandler: any;
let videoElementsToMute: HTMLVideoElement[] = [
  video1 as HTMLVideoElement,
  video2 as HTMLVideoElement,
]
let audioElementsToMute: AudioPlayer[] = [
  mainAudioPlayer,
  effectsAudioPlayer1,
  effectsAudioPlayer2
]
const allowableWidth: number = 1024
const allowableHeight: number = 576

/* VIDEO */
let playAudioAndVideo: boolean = true;
export { playAudioAndVideo };
let audioLoaded: boolean = false;
let modelLoaded: boolean = false;
let handsLoaded: boolean = false;
let rewardFinishedBound: any;

/* GESTURES */
let enableGestures: boolean = true;
let gesturesAreEnabled: boolean = false;

/* ANALYTICS */
let enableAnalytics: boolean = true;

/* FUNCTIONS */
/* START */
const start = () => {
  console.log("Start");
  console.log("Version: ", version);

  function resizedw() {
    caterForScreenSize(false);
  }

  var doit: any;
  window.addEventListener("resize", (event: Event) => {
    window.clearTimeout(doit);
    doit = window.setTimeout(resizedw, 100);
  });

  let compatible = isCompatible();
  console.log("Is compatible?: ", compatible);
  if (!compatible) {
    fitPage();
    showElements(true);
    scenePlayer(sceneInCompatible)()
    return
  }

  caterForScreenSize(true);
}

const caterForScreenSize = (pageLoad: boolean) => {
  screenBigEnough = ((window.innerWidth >= allowableWidth) && (window.innerHeight >= allowableHeight));
  screenBigEnough ? console.log("Screen big enough") : console.log("Screen not big enough")
  if (pageLoad) {
    setUpElements();
    initialiseVariables();
    loadPageAnalytics();
    createVideoWatcher();
    createGestureWatcher();
  }

  fitPage();
  showElements(pageLoad);

  if (screenBigEnough) {
    /* CHANGE START */
    if (!screenBigEnoughBeforeResize) {
      if (storedStoryScene) scenePlayer(storedStoryScene.sceneFunction)();
      else {
        scenePlayer(landingBit1)(); // landing
        // scenePlayer(scene1)(); // loader
        // scenePlayer(scene2Bit1)(); // title
        // scenePlayer(scene3Bit1)(); // permissions
        // scenePlayer(scene4Bit1)(); // Hi
        // scenePlayer(scene4Bit2)(); // choose
        // scenePlayer(chapterVideo, Character.Emma, 3)();
        // scenePlayer(chapterVideo, Character.Ravi, 1)();
        // scenePlayer(sceneWellDone)(); // well done
        // scenePlayer(sceneTheEnd)(); // end
      }
    }
  } else {
    if (screenBigEnoughBeforeResize) {
      storedStoryScene = currentStoryScene
      scenePlayer(sceneTooSmall)();
    }
  }
  screenBigEnoughBeforeResize = screenBigEnough
}

const isCompatible = (): boolean => {
  supportsES6 = detect.all('class', 'spread', 'let', 'arrowFunction');
  supportsES6 ? console.log("ES6 supported") : console.log("ES6 not supported")
  supportsWebGL2 = !!document.createElement('canvas').getContext('webgl2')
  supportsWebGL2 ? console.log('WebGL2 supported') : console.log('WebGL2 not supported')
  return (supportsES6 && supportsWebGL2);
}

const sceneInCompatible = () => {
  storyTransition();
  currentScene.mainAnim = Helper.loadAnimation(bodymovin, currentScene.elem.id, lotties["incompatible"], true, true, null, null, mainSpeed);
  currentScene.mainAnim.addEventListener("data_ready", sceneInCompatibleData);
}

const sceneInCompatibleData = () => {
  currentScene.mainAnim.removeEventListener("data_ready", sceneInCompatibleData);
  Helper.show(incompatibleText);
  sceneTransition();
  currentScene.exitFns.push(() => {
    Helper.hide(incompatibleText);
  })
}

const sceneTooSmall = () => {
  storyTransition();
  currentScene.mainAnim = Helper.loadAnimation(bodymovin, currentScene.elem.id, lotties["incompatible"], true, true, null, null, mainSpeed);
  currentScene.mainAnim.addEventListener("data_ready", sceneTooSmallData);
}

const sceneTooSmallData = () => {
  currentScene.mainAnim.removeEventListener("data_ready", sceneTooSmallData);
  Helper.show(tooSmallText);
  sceneTransition();
  currentScene.exitFns.push(() => {
    Helper.hide(tooSmallText);
  })
}

const landingBit1 = () => {
  storyTransition();
  currentScene.mainAnim = Helper.loadAnimation(bodymovin, currentScene.elem.id, lotties["landing"], true, true, null, null, mainSpeed);
  currentScene.mainAnim.addEventListener("data_ready", landingBit1Data);
}

const landingBit1Data = () => {
  currentScene.mainAnim.removeEventListener("data_ready", landingBit1Data);
  landingButton.addEventListener("click", landingButtonClickHandler, { once: true });
  setUpMouseButtonInteractions(landingButton, makeButtonLottiePath("landing", "hover"), makeButtonLottiePath("landing", "click"));
  sceneTransition();
  currentScene.exitFns.push(landingBit1Exit);
  //  landingButton.click();
  //  helpButtonWithContents.click();
}

const landingButtonClickHandler = (event: Event) => {
  scenePlayer(scene1)();
}

const landingBit1Exit = () => {
  landingButton.removeEventListener("click", landingButtonClickHandler);
  breakDownMouseButtonInteractions(landingButton);
}

const scene1 = () => {
  storyTransition();
  currentScene.mainAnim = Helper.loadAnimation(bodymovin, currentScene.elem.id, lotties["scene1"], true, false, null, scenes["scene1"]["bit1"], mainSpeed);
  currentScene.mainAnim.addEventListener("data_ready", scene1Data);
  currentScene.mainAnim.addEventListener("config_ready", configReady);
  let remover: any = currentScene.mainAnim.addEventListener("complete", testLoaded);
  currentScene.exitFns.push(remover);
}

const scene1Data = () => {
  currentScene.mainAnim.removeEventListener("data_ready", scene1Data);
  sceneTransition();
  Helper.hide(landingButton);
  mainAudioPlayer.loadAudio("scene2");
  mainAudioPlayer.audio.addEventListener("canplaythrough", audioLoadedHandler);
  scene1PlayEffectsAudio();
  setTimeout(() => {
    checkIsLoaded();
  }, 7000);
}

const scene1PlayEffectsAudio = () => {
  Helper.playAudioAfterTimeout(effectsAudioPlayer1, "effects-loader1");
  Helper.playAudioAfterTimeout(effectsAudioPlayer2, "effects-loader2");
  Helper.playAudioAfterTimeout(effectsAudioPlayer1, "effects-loader3");
  Helper.playAudioAfterTimeout(effectsAudioPlayer2, "effects-loader4");
  Helper.playAudioAfterTimeout(effectsAudioPlayer1, "effects-loader5");
  Helper.playAudioAfterTimeout(effectsAudioPlayer2, "effects-loader6");
  Helper.playAudioAfterTimeout(effectsAudioPlayer1, "effects-loader7");
  Helper.playAudioAfterTimeout(effectsAudioPlayer2, "effects-loader8");
  Helper.playAudioAfterTimeout(effectsAudioPlayer1, "effects-loader9");
  Helper.playAudioAfterTimeout(effectsAudioPlayer2, "effects-loader10");
  Helper.playAudioAfterTimeout(effectsAudioPlayer1, "effects-loader11");
}

const checkIsLoaded = () => {
  if (audioLoaded && modelLoaded && handsLoaded) {
    isLoaded = true;
    mainAudioPlayer.audio.loop = true;
    mainAudioPlayer.audio.addEventListener("ended", audioEndedHandler);
    mainAudioPlayer.play();
  }
}

const testLoaded = () => {
  if (isLoaded) {
    currentScene.mainAnim.removeEventListener("complete", testLoaded);
    scenePlayer(scene2Bit1)();
  } else {
    currentScene.mainAnim.playSegments(scenes["scene1"]["bit1"], true);
    scene1PlayEffectsAudio();
    setTimeout(() => {
      checkIsLoaded();
    }, 5000);
  }
}

const audioLoadedHandler = () => {
  mainAudioPlayer.audio.removeEventListener("canplaythrough", audioLoadedHandler);
  console.log("audio loaded");
  audioLoaded = true;
}

const modelLoadedHandler = () => {
  console.log("model loaded");
  modelLoaded = true;
}

const handsLoadedHandler = () => {
  console.log("hands loaded");
  handsLoaded = true;
}

const configReady = () => {
  currentScene.mainAnim.removeEventListener("config_ready", configReady);
  console.log("config_ready!");
}

const scene2Bit1 = () => {
  storyTransition();
  currentScene.mainAnim = Helper.loadAnimation(bodymovin, currentScene.elem.id, lotties["scene2"], true, false, null, null, mainSpeed);
  currentScene.mainAnim.addEventListener("data_ready", scene2Bit1Data);
}

const scene2Bit1Data = () => {
  currentScene.mainAnim.removeEventListener("data_ready", scene2Bit1Data);
  sceneTransition();
  let remover: any = currentScene.mainAnim.addEventListener("complete", (event: Event) => {
    scenePlayer(scene3Bit1)();
  });
  currentScene.exitFns.push(remover);
}

const audioEndedHandler = (event: Event) => {
  mainAudioPlayer.audio.removeEventListener("ended", audioEndedHandler);
  mainAudioPlayer.audio.loop = false;
}

const scene3Bit1 = () => {
  storyTransition();
  currentScene.mainAnim = Helper.loadAnimation(bodymovin, currentScene.elem.id, lotties["scene3"], true, false, null, scenes["scene3"]["bit1"], mainSpeed);
  currentScene.mainAnim.addEventListener("data_ready", scene3Bit1Data);
  currentScene.mainAnim.addEventListener("complete", scene3Bit2);
}

const scene3Bit1Data = () => {
  currentScene.mainAnim.removeEventListener("data_ready", scene3Bit1Data);
  sceneTransition();
  effectsAudioPlayer1.setAndPlayAudio("effects-permissions1");
}

const scene3Bit2 = () => {
  currentScene.mainAnim.playSegments(scenes["scene3"]["bit2"], true);
  effectsAudioPlayer1.setAndPlayAudio("effects-permissions3", false);
  if (!checkedVidPerms) {
    checkedVidPerms = true;
    if (isLocalEnv()) {
      videoPerms();
    } else {
      checkDevices();
    }
  }
}

const checkDevices = () => {
  let foundAtLeastOneVid = false;
  navigator.mediaDevices.enumerateDevices().then((devices) => {
    devices.forEach((device) => {
      if (device.kind == "videoinput" && device.deviceId !== "") {
        foundAtLeastOneVid = true;
      }
    });

    if (foundAtLeastOneVid) {
      if (showAlreadyGivenPermsDialog()) {
        videoPerms();
      } else {
        alert(errorMsgs["errMsgDeclinedPermsVideoLater"]);
        window.location.reload();
      }
    } else {
      videoPerms();
    }
  }).catch((err) => {
    console.log(err.name + ": " + err.message);
    videoPerms();
  });
}

const showAlreadyGivenPermsDialog = () => {
  return confirm(errorMsgs["alreadyGivenPermsMsg"]);
}

const videoPerms = (playScene: boolean = true) => {
  let media = navigator.mediaDevices.getUserMedia({
    video: {
      width: {
        min: allowableWidth
      },
      height: {
        min: allowableHeight
      }
    }
  });

  media
    .then((stream) => {
      webcamStream = stream;
      if (playScene) scene3Bit3();
    })
    .catch((e) => {
      console.error("media devices error: ", e.name, e.message);

      if (e.name == "OverconstrainedError") {
        alert(errorMsgs["errMsgInsuffUserMedia"]);
        scenePlayer(landingBit1)();
        return
      }

      if (e.name == "NotAllowedError") {
        alert(errorMsgs["errMsgDeclinedPermsVideo"]);
        scenePlayer(landingBit1)();
        return
      }

      alert(errorMsgs["errMsgNoWebcam"]);
      scenePlayer(landingBit1)();
    })
}

const scene3Bit3 = () => {
  currentScene.mainAnim.removeEventListener("complete", scene3Bit2);
  currentScene.mainAnim.playSegments(scenes["scene3"]["bit3"], true);
  effectsAudioPlayer1.setAndPlayAudio("effects-permissions7", false);
  let remover: any = currentScene.mainAnim.addEventListener("complete", scenePlayer(scene4Bit1));
  currentScene.exitFns.push(remover);
}

const scene4Bit1 = () => {
  Helper.addDisplayFlex(loadingIcon)
  Helper.show(loadingIcon)
  storyTransition();
  currentScene.mainAnim = Helper.loadAnimation(bodymovin, currentScene.elem.id, lotties["scene4"], true, false, null, null, mainSpeed);
  currentScene.mainAnim.addEventListener("data_ready", scene4Bit1Data);
  currentScene.mainAnim.addEventListener("complete", scene4Bit1Pause);
}

const scene4Bit1Data = () => {
  Helper.hide(loadingIcon)
  Helper.removeDisplayFlex(loadingIcon)
  currentScene.mainAnim.removeEventListener("data_ready", scene4Bit1Data);
  sceneTransition();
  Helper.playAudioAfterTimeout(effectsAudioPlayer1, "effects-permissions9");
  Helper.playAudioAfterTimeout(effectsAudioPlayer1, "effects-permissions10");
  Helper.playAudioAfterTimeout(effectsAudioPlayer1, "effects-permissions11");
  Helper.playAudioAfterTimeout(effectsAudioPlayer1, "effects-permissions12");
  Helper.playAudioAfterTimeout(effectsAudioPlayer1, "effects-permissions12a");
  Helper.playAudioAfterTimeout(effectsAudioPlayer2, "effects-permissions12b");
  setTimeout(() => {
    scene4Button1.addEventListener("click", (event: Event) => {
      scenePlayer(scene4Bit2)()
    }, { once: true });
    chooseRaviEmmaHandler = handleREPracticePrediction.bind(event, lotties);
    gestureWatcher.addEventListener("prediction", chooseRaviEmmaHandler);
    watchForGestures();
    openWebcamViewer.addEventListener("click", showWebcamViewer);
    closeWebcamViewer.addEventListener("click", hideWebcamViewer);
    showWebcamViewer();
    charRolloverButton1.addEventListener("mouseover", scene4Bit1PracticeRollover1);
    charRolloverButton2.addEventListener("mouseover", scene4Bit1PracticeRollover2);
    Helper.show(charRolloverButton1);
    Helper.show(charRolloverButton2);
    Helper.show(charRollover1);
    Helper.show(charRollover2);
    setUpMouseButtonInteractions(scene4Button1, makeButtonLottiePath("hi", "hover"), makeButtonLottiePath("hi", "click"));
  }, (18000 / mainSpeed));
}

const scene4Bit1PracticeRollover1 = () => {
  currentScene.practiceAnim = Helper.loadAnimation(bodymovin, "anim-char-rllvr-1", lotties["scene4-ravi-rollover"], true, false, null, null, mainSpeed);
  Helper.playAudioAfterTimeout(effectsAudioPlayer1, "effects-permissions19");
  currentScene.practiceAnim.addEventListener("complete", () => {
    if (currentScene.practiceAnim) currentScene.practiceAnim.destroy();
  });
}

const scene4Bit1PracticeRollover2 = () => {
  currentScene.practiceAnim = Helper.loadAnimation(bodymovin, "anim-char-rllvr-2", lotties["scene4-emma-rollover"], true, false, null, null, mainSpeed);
  Helper.playAudioAfterTimeout(effectsAudioPlayer1, "effects-permissions20");
  currentScene.practiceAnim.addEventListener("complete", () => {
    if (currentScene.practiceAnim) currentScene.practiceAnim.destroy();
  });
}

const scene4Bit1Pause = () => {
  currentScene.mainAnim.removeEventListener("complete", scene4Bit1Pause);
}

const scene4Bit2 = () => {
  Helper.addDisplayFlex(loadingIcon)
  Helper.show(loadingIcon)
  watchForGestures();
  gestureWatcher.removeEventListener("prediction", chooseRaviEmmaHandler);
  charRolloverButton1.removeEventListener("mouseover", scene4Bit1PracticeRollover1);
  charRolloverButton2.removeEventListener("mouseover", scene4Bit1PracticeRollover2);
  breakDownMouseButtonInteractions(scene4Button1);
  Helper.hide(charRolloverButton1);
  Helper.hide(charRolloverButton2);
  Helper.hide(charRollover1);
  Helper.hide(charRollover2);
  storyTransition();
  let segmentToPlay: [number, number] = jumpToCharacterSelectDueToRestart ? scenes["scene4"]["restarting"] : null;
  currentScene.mainAnim = Helper.loadAnimation(bodymovin, currentScene.elem.id, lotties["scene4-choose"], true, false, null, segmentToPlay, mainSpeed);
  currentScene.mainAnim.addEventListener("data_ready", scene4Bit2Data);
  currentScene.mainAnim.addEventListener("complete", scene4Bit2Pause);
  gestureWatcher.addEventListener("prediction", handleSc4RaviEmmaPrediction);
  resetGlobalRewardsShown();
  resetVideoTimesSeen();
  currentScene.exitFns.push(scene4Bit2Exit);
}

const resetVideoTimesSeen = () => {
  //TODO test that looping worked
  for (let id in storyScenes) {
    storyScenes[id].videoTime = 0
    storyScenes[id].visited = false
  }
}

const scene4Bit2Data = () => {
  gestureWatcher.sendEvents = true
  Helper.hide(loadingIcon)
  Helper.removeDisplayFlex(loadingIcon)
  currentScene.mainAnim.removeEventListener("data_ready", scene4Bit2Data);
  let timeout: number = jumpToCharacterSelectDueToRestart ? 500 : 1500;
  scene4Timeout1 = window.setTimeout(() => {
    setUpMouseButtonInteractions(scene4ChooseRaviButton, makeCharacterButtonLottiePath("choose", Character.Ravi, "hover"), makeCharacterButtonLottiePath("choose", Character.Ravi, "click"));
    setUpMouseButtonInteractions(scene4ChooseEmmaButton, makeCharacterButtonLottiePath("choose", Character.Emma, "hover"), makeCharacterButtonLottiePath("choose", Character.Emma, "click"));
    scene4ChooseRaviButton.addEventListener("click", scene4ChooseRaviButtonClickHandler);
    scene4ChooseEmmaButton.addEventListener("click", scene4ChooseEmmaButtonClickHandler);
  }, (timeout / mainSpeed));
  if (!webcamViewerShowing) Helper.show(openWebcamViewer);
  sceneTransition();
  effectsAudioPlayer1.setAndPlayAudio("effects-permissions13", false);
  if (!jumpToCharacterSelectDueToRestart) {
    scene4Timeout2 = Helper.playAudioAfterTimeout(effectsAudioPlayer1, "effects-permissions17");
    scene4Timeout3 = Helper.playAudioAfterTimeout(effectsAudioPlayer1, "effects-permissions18");
  }
}

const scene4ChooseRaviButtonClickHandler = (event: Event) => {
  scenePlayer(chooseRaviHandlerScene4)();
}

const scene4ChooseEmmaButtonClickHandler = (event: Event) => {
  scenePlayer(chooseEmmaHandlerScene4)();
}

const scene4Bit2Pause = () => {
  currentScene.mainAnim.removeEventListener("complete", scene4Bit2Pause);
}

const scene4Bit2Exit = () => {
  mainAudioPlayer.fadeAndStopAudio(0);
  gestureWatcher.removeEventListener("prediction", handleSc4RaviEmmaPrediction);
  window.clearTimeout(scene4Timeout1);
  window.clearTimeout(scene4Timeout2);
  window.clearTimeout(scene4Timeout3);
  breakDownMouseButtonInteractions(scene4ChooseRaviButton);
  breakDownMouseButtonInteractions(scene4ChooseEmmaButton);
  scene4ChooseRaviButton.removeEventListener("click", scene4ChooseRaviButtonClickHandler);
  scene4ChooseEmmaButton.removeEventListener("click", scene4ChooseEmmaButtonClickHandler);
}

const chooseEmmaHandlerScene4 = () => {
  Helper.addDisplayFlex(loadingIcon)
  Helper.show(loadingIcon)
  console.log("choose Emma");
  storyTransition();
  currentScene.mainAnim = Helper.loadAnimation(bodymovin, currentScene.elem.id, lotties["scene4-emma-transit"], true, false, null, [10, 57], mainSpeed);
  currentScene.mainAnim.addEventListener("data_ready", chooseEmmaHandlerScene4Data);
  currentScene.mainAnim.addEventListener("complete", (event: Event) => {
    scenePlayer(chapterTitle, Character.Emma, 1)();
  });
}

const chooseEmmaHandlerScene4Data = () => {
  Helper.hide(loadingIcon)
  Helper.removeDisplayFlex(loadingIcon)
  currentScene.mainAnim.removeEventListener("data_ready", chooseEmmaHandlerScene4Data);
  sceneTransition();
  effectsAudioPlayer1.setAndPlayAudio("effects-permissions15", false);
}

const chooseRaviHandlerScene4 = () => {
  Helper.addDisplayFlex(loadingIcon)
  Helper.show(loadingIcon)
  console.log("choose Ravi");
  storyTransition();
  currentScene.mainAnim = Helper.loadAnimation(bodymovin, currentScene.elem.id, lotties["scene4-ravi-transit"], true, false, null, null, mainSpeed);
  currentScene.mainAnim.addEventListener("data_ready", chooseRaviHandlerScene4Data);
  currentScene.mainAnim.addEventListener("complete", (event: Event) => {
    scenePlayer(chapterTitle, Character.Ravi, 1)();
  });
}

const chooseRaviHandlerScene4Data = () => {
  Helper.hide(loadingIcon)
  Helper.removeDisplayFlex(loadingIcon)
  currentScene.mainAnim.removeEventListener("data_ready", chooseRaviHandlerScene4Data);
  sceneTransition();
  effectsAudioPlayer1.setAndPlayAudio("effects-permissions16", false);
}

const chapterTitle = (event: Event) => {
  storyTransition();
  currentScene.mainAnim = Helper.loadAnimation(bodymovin, currentScene.elem.id, makeChapterLottie("title"), true, false, null, null, mainSpeed);
  currentScene.mainAnim.addEventListener("data_ready", chapterTitleHandlerData);
  currentScene.mainAnim.addEventListener("complete", chapterTitleHandlerComplete);
  gestureWatcher.addEventListener("prediction", handleRaviEmmaPrediction);
  watchForGestures();
  currentScene.exitFns.push(titleChapterHandlerExit);
}

const chapterTitleHandlerData = () => {
  currentScene.mainAnim.removeEventListener("data_ready", chapterTitleHandlerData);
  effectsAudioPlayer1.setAndPlayAudio("chapter-title");
  sceneTransition();
  gestureWatcher.sendEvents = true
}

const chapterTitleHandlerComplete = (event: Event) => {
  currentScene.mainAnim.removeEventListener("complete", chapterTitleHandlerComplete);
  scenePlayer()();
}

const titleChapterHandlerExit = () => {
  if (previousScene.mainAnim) previousScene.mainAnim.destroy();
}

const chapterPractice = () => {
  storyTransition();
  currentScene.mainAnim = Helper.loadAnimation(bodymovin, currentScene.elem.id, makeChapterLottie("practice"), true, false, null, practiceFrames[currentStoryScene.character][currentStoryScene.chapterLabel]["bit1"], mainSpeed);
  currentScene.mainAnim.addEventListener("data_ready", chapterPracticeData);
  currentScene.mainAnim.addEventListener("complete", (event: Event) => {
    scene6Button1.addEventListener("click", chapterPracticeClickHandler, { once: true });
    scene6Button2.addEventListener("mouseover", chapterDemoRollover1);
    scene6Button3.addEventListener("mouseover", chapterDemoRollover2);
    Helper.show(scene6Button2);
    Helper.show(scene6Button3);
    setUpMouseButtonInteractions(scene6Button1, makeCharacterButtonLottiePath("chapter", currentStoryScene.character, "hover"), makeCharacterButtonLottiePath("chapter", currentStoryScene.character, "click"));
    practicePredictionHandler = handlePracticePrediction.bind(event, signs, currentStoryScene);
    gestureWatcher.addEventListener("prediction", practicePredictionHandler);
    watchForGestures();
  });
  currentScene.exitFns.push(chapterPracticeExit);
}

const chapterPracticeClickHandler = () => {
  gestureWatcher.removeEventListener("prediction", practicePredictionHandler);
  currentScene.mainAnim.addEventListener("complete", (event: Event) => {
    currentScene.mainAnim.addEventListener("complete");
    scenePlayer()();
  });
  currentScene.mainAnim.playSegments(practiceFrames[currentStoryScene.character][currentStoryScene.chapterLabel]["bit2"], true);
}

const chapterPracticeData = () => {
  currentScene.mainAnim.removeEventListener("data_ready", chapterPracticeData);
  sceneTransition();
  gestureWatcher.sendEvents = true
}

const chapterPracticeExit = () => {
  gestureWatcher.removeEventListener("prediction", practicePredictionHandler);
  scene6Button1.removeEventListener("click", chapterPracticeClickHandler);
  previousScene.mainAnim.removeEventListener("complete");
  scene6Button2.removeEventListener("mouseover", chapterDemoRollover1);
  scene6Button3.removeEventListener("mouseover", chapterDemoRollover2);
  breakDownMouseButtonInteractions(scene6Button1);
  Helper.hide(scene6Button2);
  Helper.hide(scene6Button3);
  if (previousScene.mainAnim) previousScene.mainAnim.destroy();
  if (previousScene.practiceAnim) previousScene.practiceAnim.destroy();
}

const setUpPractice = () => {
  scene6Button2.addEventListener("mouseover", chapterDemoRollover1);
  scene6Button3.addEventListener("mouseover", chapterDemoRollover2);
}

const breakDownPractice = () => {
  scene6Button2.removeEventListener("mouseover", chapterDemoRollover1);
  scene6Button3.removeEventListener("mouseover", chapterDemoRollover2);
}

const chapterDemoRollover1 = () => {
  breakDownPractice();
  Helper.show(animChPrDemo);
  currentScene.practiceAnim = Helper.loadAnimation(bodymovin, "anim-ch-prac-demo", makeChapterLottie(makeSignName(1)), true, false, null, null, mainSpeed);
  currentScene.practiceAnim.addEventListener("complete", (event: Event) => {
    Helper.hide(animChPrDemo);
    if (currentScene.practiceAnim) currentScene.practiceAnim.destroy();
    setUpPractice();
  }, { once: true });
}

const chapterDemoRollover2 = () => {
  breakDownPractice();
  Helper.show(animChPrDemo);
  currentScene.practiceAnim = Helper.loadAnimation(bodymovin, "anim-ch-prac-demo", makeChapterLottie(makeSignName(2)), true, false, null, null, mainSpeed);
  currentScene.practiceAnim.addEventListener("complete", (event: Event) => {
    Helper.hide(animChPrDemo);
    if (currentScene.practiceAnim) currentScene.practiceAnim.destroy();
    setUpPractice();
  }, { once: true });
}

const chapterVideo = async (event: Event) => {
  Helper.addDisplayFlex(loadingIcon)
  Helper.show(loadingIcon)
  videoWatcher.addEventListener("rewardSignFrame", handleRewardSignFrame);
  storyTransition();
  helpButtonWithContents.addEventListener("click", showHelp);
  Helper.addDisplayFlex(helpButtonWithContents);
  currentChapter.addEventListener("ended", videoSceneEnded, { once: true });
  previousScene.destroy();
  await currentChapter.play();
  chapterVideoPlaying();
  watchForGestures();
  currentScene.exitFns.push(vidSceneExit);
}

const chapterVideoPlaying = () => {
  Helper.hide(loadingIcon)
  Helper.removeDisplayFlex(loadingIcon)
  sceneTransition(false);
  setChapterNav();
  setChapterNavColour();
  Helper.show(chapterNav);

  Helper.show(switchRaviSign);
  Helper.show(switchButton);
  Helper.show(rewardSign1Elem);
  Helper.show(rewardSign2Elem);
  let switchLottie: string = (currentStoryScene.character === Character.Emma) ? "switch_ravi" : "switch_emma";
  currentScene.switchAnim = Helper.loadAnimation(bodymovin, raviSwitcherElem, lotties[switchLottie], true, false, null, null, rewardSignSpeed);
  switchButton.addEventListener("mouseover", playSwitcher);
  gestureWatcher.sendEvents = true
};

const videoSceneEnded = () => {
  gestureWatcher.sendEvents = false
  scenePlayer()();
}

const vidSceneExit = () => {
  switchButton.removeEventListener("mouseover", playSwitcher);
  Helper.hide(switchButton);
  Helper.hide(chapterNav);
  delChapterNav();
  if (previousChapter) previousChapter.removeEventListener("ended", videoSceneEnded);
  if (previousScene.switchAnim) previousScene.switchAnim.destroy();
  videoWatcher.removeEventListener("rewardSignFrame", handleRewardSignFrame);
  Helper.hide(rewardSign1Elem);
  Helper.hide(rewardSign2Elem);
  Helper.hide(animationRewardRollover1Button);
  Helper.hide(animationRewardRollover2Button);
  animationRewardRollover1Button.onmouseover = null;
  animationRewardRollover2Button.onmouseover = null;
}

const raviSwitcherIdle = (anim: any, event: Event) => {
  anim.playSegments(scenes["ravi"]["bit2"], true);
}

const playSwitcher = () => {
  if (currentScene.switchAnim) currentScene.switchAnim.playSegments(scenes["ravi"]["bit1"], true);
}

const makeSignName = (signNo: number): string => {
  return signs[currentStoryScene.character][currentStoryScene.chapterLabel][signNo];
}

const storyTransition = (idx: number = null) => {
  // Might need to reimplement this to cope with the Wave
  //  if (!currentStoryScene.chapterNumber && previousStoryScene) {
  //    currentStoryScene.chapterNumber = previousStoryScene.chapterNumber;
  //  }
  previousScene = currentScene;
  currentScene = new Scene(currentStoryScene.type, previousScene);
  previousChapter = currentChapter;
  if (currentStoryScene.type == SceneType.Video) {
    currentChapter = new Chapter(currentStoryScene.character, currentStoryScene, currentScene, videoWatcher);
    videoWatcher.reset();
    videoWatcher.setInfo(currentChapter, currentScene, currentStoryScene.character);
    currentScene.setChapter(currentChapter);
  } else {
    currentChapter = null;
  }
}

const sceneWellDone = () => {
  Helper.addDisplayFlex(loadingIcon)
  Helper.show(loadingIcon)
  storyTransition();
  currentScene.mainAnim = Helper.loadAnimation(bodymovin, currentScene.elem.id, lotties["welldone_colours"], true, false, null, null, mainSpeed);
  currentScene.mainAnim.addEventListener("data_ready", wellDoneData);
  currentScene.mainAnim.addEventListener("complete", (event: Event) => {
    currentScene.mainAnim.playSegments(scenes["well_done"]["bit1"], true);
    currentScene.mainAnim.loop = true;
  });
  currentScene.exitFns.push(wellDoneExit);
}

const wellDoneData = () => {
  Helper.hide(loadingIcon)
  Helper.removeDisplayFlex(loadingIcon)
  wellDoneStartLink.addEventListener("click", wellDoneLink2Hndlr);
  wellDoneCreditsLink.addEventListener("click", wellDoneLink1Hndlr);
  wellDoneReadAboutLink.addEventListener("click", wellDoneLink3Hndlr);
  let score: number = calculateWellDoneScore();
  let scoreText: string = "You signed ";
  scoreText += (score === 0) ? "none" : "" + score;
  scoreText += " of the 12 chapter signs!";
  wellDoneScore.innerText = scoreText;
  Helper.show(wellDone);
  Helper.addDisplayFlex(wellDone);
  currentScene.mainAnim.removeEventListener("data_ready", wellDoneData);
  hideWebcamViewer();
  Helper.hide(openWebcamViewer);
  sceneTransition();
}

const wellDoneLink1Hndlr = (event: Event) => {
  helpOpenedHandler = () => {
    helpCredits.scrollIntoView()
    helpOpenedHandler = null;
  }
  showHelp(null);
}

const wellDoneLink2Hndlr = (event: Event) => {
  wellDoneStartLink.removeEventListener("click", wellDoneLink2Hndlr);
  jumpToCharacterSelectDueToRestart = true;
  scenePlayer(scene4Bit2)();
}

const wellDoneLink3Hndlr = (event: Event) => {
  wellDoneReadAboutLink.removeEventListener("click", wellDoneLink2Hndlr);
  showReadAbout();
}

const wellDoneExit = () => {
  Helper.hide(wellDone);
  Helper.removeDisplayFlex(wellDone);
  wellDoneCreditsLink.removeEventListener("click", wellDoneLink1Hndlr);
  wellDoneStartLink.removeEventListener("click", wellDoneLink2Hndlr);
  wellDoneReadAboutLink.removeEventListener("click", wellDoneLink3Hndlr);
  if (previousScene.mainAnim) previousScene.mainAnim.destroy();
}

const calculateWellDoneScore = (): number => {
  let score: number = 0;
  for (let idx in countSignsDone) {
    if (countSignsDone[idx]) score++;
  }
  return score;
}

const sceneTheEnd = () => {
  Helper.addDisplayFlex(loadingIcon)
  Helper.show(loadingIcon)
  storyTransition();
  if (enableGestures) gestureWatcher.removeEventListener("prediction", handleRaviEmmaPrediction);
  currentScene.mainAnim = Helper.loadAnimation(bodymovin, currentScene.elem.id, lotties["end"], true, true, null, null, mainSpeed);
  currentScene.mainAnim.addEventListener("data_ready", theEndData);
}

const theEndData = () => {
  Helper.hide(loadingIcon)
  Helper.removeDisplayFlex(loadingIcon)
  currentScene.mainAnim.removeEventListener("data_ready", theEndData);
  sceneTransition();
  currentScene.exitFns.push(theEndExit);
  setTimeout(() => {
    scenePlayer()();
  }, 3000);
}

const theEndExit = () => {
  if (previousScene.mainAnim) previousScene.mainAnim.destroy();
}

/* GENERAL */
// Hide message for old browsers if we get this far
oldBrowserContainer.classList.add("loaded");

const getSentryEnvTag = () => {
  if (window.location.href.includes("localhost:8080")) return 'local'
  if (window.location.href.includes("ravi-emma.dev.sbsod.com")) return 'development'
  if (window.location.href.includes("ravi-emma.qa.sbsod.com")) return 'qa'
  if (window.location.href.includes("ravi-emma.stg.sbsod.com")) return 'staging'
  if (window.location.href.includes("ravi-emma.pr.sbsod.com") || window.location.href.includes("raviandemma.sbs.com.au")) return 'production'
  return 'unknown'
}

document.onreadystatechange = () => {
  if (document.readyState === 'complete') {
    // document ready
    Sentry.init({
      dsn: "https://192377fc4a174e23a0df232c8d6be4f3@o945013.ingest.sentry.io/5893597",
      integrations: [new Integrations.BrowserTracing()],
      environment: getSentryEnvTag(),

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });

    try {
      start();
    } catch (e) {
      console.log("General error caught: ", e);
      alert(errorMsgs["generalErrorMsg"]);
    }
  }
};

// TODO i guess delete this sometime in the future
window.ravi = function() {
  storeCurrentStoryPosn();
  scenePlayer(playRaviWave)();
}


window.emma = function() {
  storeCurrentStoryPosn();
  scenePlayer(playEmmaWave)();
}

/* HANDLE PREDICTIONS */
const handleRaviEmmaPrediction = (customEvent: CustomEvent) => {
  let label: string = Helper.labelFromEvent(customEvent, "label");
  if ((label == "emma") && ((currentStoryScene.character == Character.Ravi) || (currentStoryScene.character == null))) {
    gestureWatcher.sendEvents = false
    storeCurrentStoryPosn();
    console.log("Switched to character: Emma");
    scenePlayer(playEmmaWave)();
  }
  if ((label == "ravi") && ((currentStoryScene.character == Character.Emma) || (currentStoryScene.character == null))) {
    gestureWatcher.sendEvents = false
    storeCurrentStoryPosn();
    console.log("Switched to character: Ravi");
    scenePlayer(playRaviWave)();
  }
}

const storeCurrentStoryPosn = () => {
  if (currentStoryScene.type == SceneType.Video) {
    currentStoryScene.videoTime = videoWatcher.getCurrentTime()
  }
}

const playEmmaWave = () => {
  Helper.addDisplayFlex(loadingIcon)
  Helper.show(loadingIcon)
  storyTransition();
  currentScene.mainAnim = Helper.loadAnimation(bodymovin, currentScene.elem.id, lotties["wave_emma"], true, false, null, null, mainSpeed);
  currentScene.mainAnim.addEventListener("data_ready", playEmmaWaveData);
  currentScene.mainAnim.addEventListener("complete", emmaWaveComplete);
  currentScene.exitFns.push(() => {
    if (previousScene.mainAnim) previousScene.mainAnim.destroy();
  });
}

const playEmmaWaveData = () => {
  Helper.hide(loadingIcon)
  Helper.removeDisplayFlex(loadingIcon)
  currentScene.mainAnim.removeEventListener("data_ready", playEmmaWaveData);
  sceneTransition();
}

const emmaWaveComplete = (event: Event) => {
  currentScene.mainAnim.removeEventListener("complete", emmaWaveComplete);
  for (let id in storyScenes) {
    let scene = storyScenes[id]
    if (scene.character == Character.Emma && scene.chapterLabel == previousStoryScene.chapterLabel && (scene.visited != true || scene.type == SceneType.Video)) {
      scenePlayer(scene.sceneFunction, Character.Emma, previousStoryScene.chapterLabel)()
      return
    }
  }
};

const playRaviWave = () => {
  Helper.addDisplayFlex(loadingIcon)
  Helper.show(loadingIcon)
  storyTransition();
  currentScene.mainAnim = Helper.loadAnimation(bodymovin, currentScene.elem.id, lotties["wave_ravi"], true, false, null, null, mainSpeed);
  currentScene.mainAnim.addEventListener("data_ready", playRaviWaveData);
  currentScene.mainAnim.addEventListener("complete", raviWaveComplete);
  currentScene.exitFns.push(() => {
    if (previousScene.mainAnim) previousScene.mainAnim.destroy();
  });
}

const playRaviWaveData = () => {
  Helper.hide(loadingIcon)
  Helper.removeDisplayFlex(loadingIcon)
  currentScene.mainAnim.removeEventListener("data_ready", playRaviWaveData);
  sceneTransition();
}

const raviWaveComplete = (event: Event) => {
  currentScene.mainAnim.removeEventListener("complete", raviWaveComplete);

  for (let id in storyScenes) {
    let scene = storyScenes[id]
    if (scene.character == Character.Ravi && scene.chapterLabel == previousStoryScene.chapterLabel && (scene.visited != true || scene.type == SceneType.Video)) {
      scenePlayer(scene.sceneFunction, Character.Ravi, previousStoryScene.chapterLabel)()
      return
    }
  }
}

const playContinueWave = () => {
  Helper.addDisplayFlex(loadingIcon)
  Helper.show(loadingIcon)
  storyTransition();
  let lottieName: string = currentStoryScene.character == Character.Emma ? "wave_emma" : "wave_ravi";
  currentScene.mainAnim = Helper.loadAnimation(bodymovin, currentScene.elem.id, lotties[lottieName], true, false, null, null, mainSpeed);
  currentScene.mainAnim.addEventListener("data_ready", playContinueWaveData);
  currentScene.mainAnim.addEventListener("complete", waveContinueComplete);
  currentScene.exitFns.push(() => {
    if (previousScene.mainAnim) previousScene.mainAnim.destroy();
  });
}

const playContinueWaveData = () => {
  Helper.hide(loadingIcon)
  Helper.removeDisplayFlex(loadingIcon)
  currentScene.mainAnim.removeEventListener("data_ready", playContinueWaveData);
  sceneTransition();
}

const waveContinueComplete = (event: Event) => {
  currentScene.mainAnim.removeEventListener("complete", waveContinueComplete);
  scenePlayer()()
}

const handleSc4RaviEmmaPrediction = (customEvent: CustomEvent) => {
  let label: string = Helper.labelFromEvent(customEvent, "label");
  if ((label == "emma") && ((currentStoryScene.character == Character.Ravi) || (currentStoryScene.character == null))) {
    console.log("character name: Emma");
    scenePlayer(chooseEmmaHandlerScene4)();
  }
  if ((label == "ravi") && ((currentStoryScene.character == Character.Emma) || (currentStoryScene.character == null))) {
    console.log("character name: Ravi");
    scenePlayer(chooseRaviHandlerScene4)();
  }
}

const handleRewardSignFrame = (rewardIndex: number, sign: string) => {
  console.log("Reward sign shown: ", sign);
  let rewardIndexAdj: number = +rewardIndex + 1;
  let rewardElemName = `anim-reward-${rewardIndexAdj}-sign`;
  let segmentToPlay: [number, number];
  if (currentStoryScene.character === null) {
    segmentToPlay = scenes[sign]["bit2"];
  } else {
    if (globalStoryRewardsShown[sign]) {
      segmentToPlay = scenes[sign]["bit2"];
    } else {
      segmentToPlay = scenes[sign]["bit1"];
      globalStoryRewardsShown[sign] = true;
    }
  }
  currentScene.rewardSignAnims[+rewardIndex] = Helper.loadAnimation(bodymovin, rewardElemName, makeChapterLottie(sign), true, false, null, segmentToPlay, rewardSignSpeed);
  const rewSignLoop = (event: Event) => {
    if (currentScene.rewardSignAnims[+rewardIndex]) currentScene.rewardSignAnims[+rewardIndex].playSegments(scenes[sign]["bit2"], true);
  }
  currentScene.rewardSignAnims[+rewardIndex].addEventListener("complete", rewSignLoop);

  rewSignSetupReplay = (event: Event) => {
    if (currentScene.rewardSignAnims[+rewardIndex]) currentScene.rewardSignAnims[+rewardIndex].removeEventListener("complete", rewSignSetupReplay);

    const rewardSignReplay = (event: Event) => {
      let elemId: string = `anim-rwrd-rllvr-${+rewardIndex + 1}`;
      currentScene.rewardSignRolloverAnimations[+rewardIndex] = Helper.loadAnimation(bodymovin, elemId, makeChapterLottie(`rollover/${sign}`), true, false, null, null, rewardSignSpeed);
      Helper.show(document.getElementById(elemId));
      currentScene.rewardSignRolloverAnimations[+rewardIndex].addEventListener("complete", (event: Event) => {
        Helper.hide(document.getElementById(elemId));
        if (currentScene.rewardSignRolloverAnimations[+rewardIndex]) currentScene.rewardSignRolloverAnimations[+rewardIndex].destroy();
      }, { once: true });
    }

    let rolloverButton: HTMLElement = document.getElementById(`anim-rwrd-rllvr-${+rewardIndexAdj}-button`);
    rolloverButton.onmouseover = rewardSignReplay;
    Helper.show(rolloverButton);
  }
  currentScene.rewardSignAnims[+rewardIndex].addEventListener("complete", rewSignSetupReplay);
  globalRewardSigns[+rewardIndex] = sign;
  if (enableGestures) gestureWatcher.addEventListener("prediction", handlePredictionReward);
}

const resetGlobalRewardsShown = () => {
  for (let sign in globalStoryRewardsShown) {
    globalStoryRewardsShown[sign] = false
  }
}

const handlePredictionReward = (customEvent: CustomEvent) => {
  let label: string = Helper.labelFromEvent(customEvent, "label");
  for (let idx in globalRewardSigns) {
    let rewardSign = globalRewardSigns[idx];
    if (!globalRewardIsPlaying && (label === rewardSign)) {
      Helper.show(animReward);
      globalRewardIsPlaying = true;
      let idxAdj: number = +idx + 1;
      let reward = rewardSign.concat("_reward");
      currentScene.rewardAnim = Helper.loadAnimation(bodymovin, "anim-reward", `lottie/rewards/${rewardSign}.json`, true, false, null, null, rewardSignSpeed);
      if (currentStoryScene.character != null) {
        currentStoryScene.character == Character.Emma ? fireAnalyticsEvent("raviandemma:route:RewardsEmma") : fireAnalyticsEvent("raviandemma:route:RewardsRavi")
      }
      let anim: any = currentScene.rewardAnim;
      rewardFinishedBound = rewardFinished.bind(event, anim);
      currentScene.rewardAnim.addEventListener("complete", rewardFinishedBound);
      effectsAudioPlayer1.setAndPlayAudio(rewardSign);
      countSignsDone[label] = true;
    }
  }
}

const rewardFinished = (anim: any, event: Event) => {
  anim.removeEventListener("complete", rewardFinishedBound);
  if (anim) anim.destroy()
  Helper.hide(animReward);
  globalRewardIsPlaying = false;
}

const handleREPracticePrediction = (lotties: PathsType, customEvent: CustomEvent) => {
  let label: string = Helper.labelFromEvent(customEvent, "label");
  if (label === "emma") {
    handlePracticeReward(1, lotties[`${label}_reward`]);
    effectsAudioPlayer1.setAndPlayAudio("effects-practice1", false);
  }
  if (label === "ravi") {
    handlePracticeReward(2, lotties[`${label}_reward`]);
    effectsAudioPlayer1.setAndPlayAudio("effects-practice1", false);
  }
}

const handlePracticePrediction = (signs: StorySignsType, storyScene: SceneSummary, customEvent: CustomEvent) => {
  if (currentStoryScene.character === undefined) return;
  let label: string = Helper.labelFromEvent(customEvent, "label");
  let rewardSign1 = signs[currentStoryScene.character][storyScene.chapterLabel][1];
  let rewardSign2 = signs[currentStoryScene.character][storyScene.chapterLabel][2];
  if (label === rewardSign1) {
    handlePracticeReward(1, lotties["left_practice_reward"]);
    effectsAudioPlayer1.setAndPlayAudio("effects-practice1", false);
  }
  if (label === rewardSign2) {
    handlePracticeReward(2, lotties["right_practice_reward"]);
    effectsAudioPlayer1.setAndPlayAudio("effects-practice1", false);
  }
}

const handlePracticeReward = (idx: number, rewardLottieName: string) => {
  if (!practiceRewardIsPlaying) {
    currentScene.practiceRewardAnim = Helper.loadAnimation(bodymovin, "anim-practice-reward", rewardLottieName, true, false, null, null, rewardSignSpeed);
    practiceRewardIsPlaying = true;
    currentScene.practiceRewardAnim.addEventListener("complete", practiceRewardComplete);
    Helper.show(animPracticeReward);
    Helper.addDisplayFlex(animPracticeReward);
  }
}

const practiceRewardComplete = () => {
  if (currentScene.practiceRewardAnim) currentScene.practiceRewardAnim.removeEventListener("complete", practiceRewardComplete);
  Helper.hide(animPracticeReward);
  Helper.removeDisplayFlex(animPracticeReward);
  if (currentScene.practiceRewardAnim) currentScene.practiceRewardAnim.destroy();
  practiceRewardIsPlaying = false;
}

const handleTryReward = (customEvent: CustomEvent) => {
  let sign: string = Helper.labelFromEvent(customEvent, "label");
  if ((sign !== "emma") && (sign !== "ravi")) {
    let anim = Helper.loadAnimation(bodymovin, elemRewardContnr.id, makeTryRewardLottie(sign), true, false, null, null, rewardSignSpeed);
    effectsAudioPlayer1.setAndPlayAudio(sign);
    console.log("Reward shown for sign: ", sign);
    anim.addEventListener("complete", (event: Event) => {
      if (anim) anim.destroy();
    });
  }
}

/* ANALYTICS */
const loadPageAnalytics = () => {
  if (enableAnalytics) {
    analytics = new Analytics();
    window.digitalData = analytics.digitalData;
    (window.observerService = window.observerService || []).push(["notify", 'pageLoadSuccess']);
  }
}

const fireAnalyticsEvent = (name: string) => {
  if (enableAnalytics) {
    analytics.addEventToDataLayer(name);
    (window.observerService = window.observerService || []).push(["notify", 'fireEvent', { eventName: 'userInteraction' }]);
  }
}

const createGestureWatcher = () => {
  if (enableGestures) {
    gestureWatcher = new GestureWatcher(Hands, Camera, drawConnectors, drawLandmarks, HAND_CONNECTIONS, document, tf);
    gestureWatcher.addEventListener("model-loaded", modelLoadedHandler);
    gestureWatcher.addEventListener("hands-loaded", handsLoadedHandler);
    gestureWatcher.loadResources();
  }
}

const createVideoWatcher = () => {
  videoWatcher = new VideoWatcher(anims, lotties, handleRewardSignFrame);
}

const fitPage = () => {
  let w: number = window.innerWidth;
  let h: number = window.innerHeight - 50;
  let sceneWidth: number;
  let sceneHeight: number;

  // fit video
  let ar: number = 0.590880;

  let ratio: number = w * ar;
  if ((w * ar) < h) {
    // constrained by width
    sceneWidth = w;
    sceneHeight = w * ar;
    incompatibleText.style.fontSize = `${(w * 0.01)}px`;
    tooSmallText.style.fontSize = `${(w * 0.015)}px`;
  } else {
    // constrained by height
    sceneWidth = h / ar;
    sceneHeight = h;
    incompatibleText.style.fontSize = `${(h * 0.016)}px`;
    tooSmallText.style.fontSize = `${(h * 0.024)}px`;
  }
  sceneInside.style.width = `${sceneWidth}px`;
  sceneInside.style.height = `${sceneHeight}px`;
}

const setUpElements = () => {
  helpButtonWithContents.addEventListener("click", showHelp);
  fullScreenButton.addEventListener("click", handleClickFullscreen);
  fullScreenEscapeButton.addEventListener("click", handleClickFullscreen);
  muteButton.addEventListener("click", handleClickMute);
  unmuteButton.addEventListener("click", handleClickMute);
  document.addEventListener("fullscreenchange", fullScreenChange);
  helpButtonWithContents.addEventListener("click", showHelp);
}

const showElements = (pageLoad: boolean) => {
  Helper.addClass(oldBrowserContainer, "loaded")
  if (pageLoad) {
    Helper.show(mainAnimElem1);
    Helper.addDisplayFlex(mainAnimElem1);
    Helper.show(fullScreenButton);
    Helper.show(muteButton);
  }
  Helper.show(sbsLogo);
  if (screenBigEnough) {
    Helper.show(buttons);
    Helper.addDisplayFlex(helpButtonWithContents);
    Helper.show(helpButtonWithContents);
  } else {
    Helper.hide(buttons);
    Helper.removeDisplayFlex(helpButtonWithContents);
    Helper.hide(helpButtonWithContents);
  }
}

const handleClickFullscreen = (event: Event) => {
  if (isInFullScreen) {
    closeFullScreen();
  } else {
    openFullScreen();
  }
}

const fullScreenChange = (event: Event) => {
  if (document.fullscreenElement) {
    console.log('Entering full-screen mode.');
    Helper.show(fullScreenEscapeButton);
    Helper.hide(fullScreenButton);
    isInFullScreen = true;
  } else {
    console.log('Leaving full-screen mode.');
    Helper.show(fullScreenButton);
    Helper.hide(fullScreenEscapeButton);
    isInFullScreen = false;
  }
}

const handleClickMute = (event: Event) => {
  if (isMuted) {
    unmute();
  } else {
    mute();
  }
}

const watchForGestures = () => {
  if (enableGestures && !gesturesAreEnabled) {
    gestureWatcher.startCamera();
    //    Helper.show(outputCanvas);
    gesturesAreEnabled = true;
  }
}

const sceneTransition = (destroyPreviousScene: boolean = true) => {
  if ((previousScene === null) || (currentScene === null)) return;
  let elem1: HTMLElement = previousScene.elem;
  let elem2: HTMLElement = currentScene.elem;
  if (currentScene.type == SceneType.Video) {
    makeVideoChapterChanges();
  } else {
    makeAnimationChapterChanges();
  }
  if (currentStoryScene.changeSceneBorder) {
    Helper.ensureSceneBorder(sceneInside, currentStoryScene.character);
  }
  if (currentStoryScene.backgroundColour) {
    Helper.ensureSceneBkgd(storyScenes, sceneInside, currentStoryScene);
  }
  Helper.addDisplayFlex(elem2);
  Helper.show(elem2);
  Helper.addDisplayFlex(sceneInside);
  Helper.show(sceneInside);
  Helper.hide(elem1);
  Helper.removeDisplayFlex(elem1);
  if (destroyPreviousScene == true) previousScene.destroy();
  previousScene = null;
  if (previousChapter) previousChapter.stop();
}

const makeVideoChapterChanges = () => {
  sbsLogo.classList.add("video-chapter");
  openWebcamViewer.classList.add("video-chapter");
  webcamVideoContainer.classList.add("video-chapter");
}

const makeAnimationChapterChanges = () => {
  sbsLogo.classList.remove("video-chapter");
  openWebcamViewer.classList.remove("video-chapter");
  webcamVideoContainer.classList.remove("video-chapter");
  if (previousScene.type == SceneType.Video) {
    if (enableGestures) gestureWatcher.removeEventListener("prediction", handlePredictionReward);
  }
}

const setChapterNav = () => {
  let chapterLabel = currentStoryScene.chapterLabel;
  for (let i = 1; i < 4; i++) {
    let liElem = document.createElement("li");
    let numberSpanElem = document.createElement("span");
    const textContent = document.createTextNode("Ch. ");
    const numberContent = document.createTextNode(`${i}`);
    liElem.appendChild(textContent);
    numberSpanElem.appendChild(numberContent);
    liElem.appendChild(numberSpanElem);
    chapterNav.appendChild(liElem);
    if (i == chapterLabel) {
      numberSpanElem.classList.add("active");
    } else {
      numberSpanElem.classList.remove("active");
      (liElem as HTMLElement).addEventListener("click", (event: Event) => {
        gestureWatcher.sendEvents = false
        storeCurrentStoryPosn();
        getChNavLinkFn(i)()
      });
    }
  }
}

const setChapterNavColour = () => {
  Helper.removeClass(chapterNav, "chapter-nav-emma");
  Helper.removeClass(chapterNav, "chapter-nav-ravi");
  if (currentStoryScene.character == Character.Emma) {
    Helper.addClass(chapterNav, "chapter-nav-emma");
  } else {
    Helper.addClass(chapterNav, "chapter-nav-ravi");
  }
}

const getChNavLinkFn = (chapterLabel: number) => {
  for (let idx in storyScenes) {
    let thisStoryScene: SceneSummary = storyScenes[idx];
    if (
      thisStoryScene.chapterLabel == chapterLabel &&
      thisStoryScene.character == currentStoryScene.character && (thisStoryScene.visited != true || thisStoryScene.type == SceneType.Video)
    ) {

      // This because scenePlayer does it own lookup
      previousStoryScene = currentStoryScene;
      currentStoryScene = thisStoryScene
      currentStoryScene.visited = true
      return thisStoryScene.sceneFunction;
    }
  }
}

const delChapterNav = () => {
  while (chapterNav.firstChild) {
    chapterNav.removeChild(chapterNav.lastChild);
  }
}

const openFullScreen = () => {
  if (docElem.requestFullscreen) {
    docElem.requestFullscreen();
  }
}

const closeFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  }
}

const mute = () => {
  for (let idx in videoElementsToMute) {
    videoElementsToMute[idx].muted = true;
  }
  for (let idx in audioElementsToMute) {
    audioElementsToMute[idx].audio.muted = true;
  }
  Helper.show(unmuteButton);
  Helper.hide(muteButton);
  isMuted = true;
}

const unmute = () => {
  for (let idx in videoElementsToMute) {
    videoElementsToMute[idx].muted = false
  }
  for (let idx in audioElementsToMute) {
    audioElementsToMute[idx].audio.muted = false
  }
  Helper.show(muteButton);
  Helper.hide(unmuteButton);
  isMuted = false;
}

const showHelp = (event: Event) => {
  infoAnim = Helper.loadAnimation(bodymovin, "anim-info", lotties["info"], true, true, null, null, mainSpeed);
  infoAnim.addEventListener("data_ready", showHelpData);
  gestureWatcher.sendEvents = false
}

const showHelpData = () => {
  if (currentStoryScene.sceneFunction == sceneWellDone) {
    Helper.removeDisplayFlex(wellDone);
    Helper.hide(wellDone);
  }
  infoAnim.removeEventListener("data_ready", showHelpData);
  Helper.show(infoAnimElem);
  helpButtonWithContents.removeEventListener("click", showHelp);
  helpButtonWithContents.classList.add("disabled");
  video1.style.position = "absolute";
  video2.style.position = "absolute";
  webcamViewerOpenWhenOpeningHelp = webcamViewerShowing;
  if (webcamViewerShowing) hideWebcamViewer()
  Helper.addDisplayFlex(help);
  Helper.show(help);
  currentScene.pause();
  helpClose.addEventListener("click", hideHelp);
  helpAnimRavi = Helper.loadAnimation(bodymovin, helpSwitchRavi.id, lotties["switch_ravi_help"], true, true, null, null, mainSpeed);
  helpAnimEmma = Helper.loadAnimation(bodymovin, helpSwitchEmma.id, lotties["switch_emma_help"], true, true, null, null, mainSpeed);
  if (helpOpenedHandler) helpOpenedHandler();
  //  helpBrRm.scrollIntoView();
}

const hideHelp = (event: Event) => {
  helpClose.removeEventListener("click", hideHelp);
  video1.style.position = "initial";
  video2.style.position = "initial";
  Helper.hide(help);
  Helper.removeDisplayFlex(help);
  if (currentStoryScene.sceneFunction == sceneWellDone) {
    Helper.addDisplayFlex(wellDone);
    Helper.show(wellDone);
  }
  if (infoAnim) infoAnim.destroy();
  if (helpAnimRavi) helpAnimRavi.destroy();
  if (helpAnimEmma) helpAnimEmma.destroy();
  if (webcamViewerOpenWhenOpeningHelp) showWebcamViewer();
  currentScene.resume();
  helpButtonWithContents.classList.remove("disabled");
  helpButtonWithContents.addEventListener("click", showHelp);
  gestureWatcher.sendEvents = true
}

const showReadAbout = () => {
  infoAnim = Helper.loadAnimation(bodymovin, "anim-info", lotties["info"], true, true, null, null, mainSpeed);
  infoAnim.addEventListener("data_ready", showReadAboutData);
}

const showReadAboutData = () => {
  infoAnim.removeEventListener("data_ready", showReadAboutData);
  Helper.addDisplayFlex(readAbout);
  Helper.show(readAbout);
  Helper.removeDisplayFlex(wellDone);
  Helper.hide(wellDone);
  Helper.show(infoAnimElem);
  video1.style.position = "absolute";
  video2.style.position = "absolute";
  currentScene.pause();
  readAboutClose.addEventListener("click", hideReadAbout);
}

const hideReadAbout = (event: Event) => {
  readAboutClose.removeEventListener("click", hideReadAbout);
  Helper.hide(infoAnimElem);
  infoAnim.destroy();
  Helper.addDisplayFlex(wellDone);
  Helper.show(wellDone);
  Helper.removeDisplayFlex(readAbout);
  Helper.hide(readAbout);
  currentScene.resume();
}

const showWebcamViewer = () => {
  if (webcamStream) (webcamVideo as HTMLVideoElement).srcObject = webcamStream;
  dragElement(webcamVideoContainer);
  Helper.show(webcamVideoContainer);
  Helper.hide(openWebcamViewer);
  chapterNav.classList.add("webcam-showing");
  webcamViewerShowing = true;
}

const hideWebcamViewer = () => {
  Helper.hide(webcamVideoContainer);
  Helper.show(openWebcamViewer);
  chapterNav.classList.remove("webcam-showing");
  (webcamVideo as HTMLVideoElement).srcObject = null;
  webcamViewerShowing = false;
}

const isLocalEnv = () => {
  return window.location.href.includes("http://localhost:8080/")
}

const getStorySceneFunction = (
  sceneFunction: SceneFunction,
  character: Character = null,
  chapterLabel: number = null,
) => {
  return getStoryScene(sceneFunction, character, chapterLabel).sceneFunction;
}

const getNextSceneStory = (): SceneSummary => {
  if (currentStoryScene.nextSceneFunction !== undefined) {
    // part of chronological story
    return getStoryScene(currentStoryScene.nextSceneFunction);
  }
  if (currentStoryScene.chapterLabel !== undefined) {
    // part of a chapter
    if (currentStoryScene.sceneFunction == chapterTitle) {
      return getStoryScene(chapterPractice, currentStoryScene.character, currentStoryScene.chapterLabel);
    }
    if (currentStoryScene.sceneFunction == chapterPractice) {
      return getStoryScene(chapterVideo, currentStoryScene.character, currentStoryScene.chapterLabel);
    }
    if (currentStoryScene.sceneFunction == chapterVideo) {
      return getStoryScene(playContinueWave, currentStoryScene.character, currentStoryScene.chapterLabel);
    }
    if (currentStoryScene.sceneFunction == playContinueWave) {
      if (currentStoryScene.chapterLabel === 3) {
        return getStoryScene(sceneTheEnd);
      } else {
        return getStoryScene(chapterTitle, currentStoryScene.character, currentStoryScene.chapterLabel + 1);
      }
    }
  }
}

const makeChapterLottieBase = () => {
  return `lottie/chapter/${Character[currentStoryScene.character]}/${currentStoryScene.chapterLabel}/${currentStoryScene.chapterPart}`;
}

const makeChapterLottie = (fileName: string): string => {
  return `${makeChapterLottieBase()}/${fileName}.json`
}

const makeButtonLottiePath = (scene: string, interactionType: string) => {
  return `lottie/button/${scene}/${interactionType}.json`;
}

const makeCharacterButtonLottiePath = (scene: string, character: Character, interactionType: string) => {
  return `lottie/button/${scene}/${Character[character]}/${interactionType}.json`;
}

const makeTryRewardLottie = (fileName: string): string => {
  return `lottie/rewards/${fileName}.json`;
}

/* STORY */
let storyScenes: SceneSummary[] = [
  {
    character: null, chapterLabel: null, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: sceneInCompatible,
    backgroundColour: "beige"
  },
  {
    character: null, chapterLabel: null, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: sceneTooSmall,
    backgroundColour: "beige"
  },
  {
    character: null, chapterLabel: null, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: landingBit1, nextSceneFunction: scene1,
    backgroundColour: "beige", analyticsInteraction: "raviandemma:route:Landing"
  },
  {
    character: null, chapterLabel: null, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: scene1, nextSceneFunction: scene2Bit1,
    backgroundColour: "yellow", analyticsInteraction: "raviandemma:route:Loader"
  },
  {
    character: null, chapterLabel: null, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: scene2Bit1, nextSceneFunction: scene3Bit1,
    backgroundColour: "beige", analyticsInteraction: "raviandemma:route:Title"
  },
  {
    character: null, chapterLabel: null, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: scene3Bit1, nextSceneFunction: scene4Bit1,
    backgroundColour: "beige", analyticsInteraction: "raviandemma:route:Permissions"
  },
  {
    character: null, chapterLabel: null, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: scene4Bit1, nextSceneFunction: scene4Bit2,
    backgroundColour: "beige", analyticsInteraction: "raviandemma:route:Introduction"
  },
  {
    character: null, chapterLabel: null, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: scene4Bit2,
    backgroundColour: "beige"
  },
  {
    character: Character.Emma, chapterLabel: 1, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: chapterTitle,
    backgroundColour: "light-blue", chapterPart: "title",
    analyticsInteraction: "raviandemma:route:WhenEmmametRavi"
  },
  {
    character: Character.Emma, chapterLabel: 1, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: chapterPractice,
    backgroundColour: "light-blue", chapterPart: "practice"
  },
  {
    character: Character.Emma, chapterLabel: 1, changeSceneBorder: true,
    type: SceneType.Video, sceneFunction: chapterVideo,
    backgroundColour: "light-blue", chapterPart: "video",
  },
  {
    character: Character.Emma, chapterLabel: 1, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: playContinueWave,
  },
  {
    character: Character.Emma, chapterLabel: 2, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: chapterTitle,
    backgroundColour: "light-blue", chapterPart: "title",
    analyticsInteraction: "raviandemma:route:EmmasTest",
  },
  {
    character: Character.Emma, chapterLabel: 2, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: chapterPractice,
    backgroundColour: "light-blue", chapterPart: "practice"
  },
  {
    character: Character.Emma, chapterLabel: 2, changeSceneBorder: true,
    type: SceneType.Video, sceneFunction: chapterVideo,
    backgroundColour: "light-blue", chapterPart: "video",
  },
  {
    character: Character.Emma, chapterLabel: 2, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: playContinueWave,
  },
  {
    character: Character.Emma, chapterLabel: 3, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: chapterTitle,
    backgroundColour: "light-blue", chapterPart: "title",
    analyticsInteraction: "raviandemma:route:TheFutureAccordingtoEmma"
  },
  {
    character: Character.Emma, chapterLabel: 3, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: chapterPractice,
    backgroundColour: "light-blue", chapterPart: "practice"
  },
  {
    character: Character.Emma, chapterLabel: 3, changeSceneBorder: true,
    type: SceneType.Video, sceneFunction: chapterVideo,
    backgroundColour: "light-blue", chapterPart: "video",
  },
  {
    character: Character.Emma, chapterLabel: 3, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: playContinueWave,
  },
  {
    character: Character.Ravi, chapterLabel: 1, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: chapterTitle,
    backgroundColour: "rose", chapterPart: "title",
    analyticsInteraction: "raviandemma:route:WhenRavimetEmma",
  },
  {
    character: Character.Ravi, chapterLabel: 1, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: chapterPractice,
    backgroundColour: "rose", chapterPart: "practice"
  },
  {
    character: Character.Ravi, chapterLabel: 1, changeSceneBorder: true,
    type: SceneType.Video, sceneFunction: chapterVideo,
    backgroundColour: "rose", chapterPart: "video",
  },
  {
    character: Character.Ravi, chapterLabel: 1, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: playContinueWave,
  },
  {
    character: Character.Ravi, chapterLabel: 2, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: chapterTitle,
    backgroundColour: "rose", chapterPart: "title",
    analyticsInteraction: "raviandemma:route:RavisTest",
  },
  {
    character: Character.Ravi, chapterLabel: 2, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: chapterPractice,
    backgroundColour: "rose", chapterPart: "practice"
  },
  {
    character: Character.Ravi, chapterLabel: 2, changeSceneBorder: true,
    type: SceneType.Video, sceneFunction: chapterVideo,
    backgroundColour: "rose", chapterPart: "video",
  },
  {
    character: Character.Ravi, chapterLabel: 2, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: playContinueWave,
  },
  {
    character: Character.Ravi, chapterLabel: 3, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: chapterTitle,
    backgroundColour: "rose", chapterPart: "title",
    analyticsInteraction: "raviandemma:route:TheFutureAccordingtoRavi",
  },
  {
    character: Character.Ravi, chapterLabel: 3, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: chapterPractice,
    backgroundColour: "rose", chapterPart: "practice"
  },
  {
    character: Character.Ravi, chapterLabel: 3, changeSceneBorder: true,
    type: SceneType.Video, sceneFunction: chapterVideo,
    backgroundColour: "rose", chapterPart: "video",
  },
  {
    character: Character.Ravi, chapterLabel: 3, changeSceneBorder: true,
    type: SceneType.Animation, sceneFunction: playContinueWave,
  },
  {
    changeSceneBorder: true, type: SceneType.Animation,
    sceneFunction: sceneWellDone, backgroundColour: "beige",
  },
  {
    changeSceneBorder: true, type: SceneType.Animation,
    sceneFunction: sceneTheEnd, backgroundColour: "beige",
    analyticsInteraction: "raviandemma:route:End", nextSceneFunction: sceneWellDone,
  },
  {
    type: SceneType.Animation, changeSceneBorder: false,
    sceneFunction: playEmmaWave, backgroundColour: "light-blue",
    analyticsInteraction: "raviandemma:route:ChooseRaviorEmma"
  },
  {
    type: SceneType.Animation, changeSceneBorder: false,
    sceneFunction: playRaviWave, backgroundColour: "rose",
    analyticsInteraction: "raviandemma:route:ChooseRaviorEmma"
  },
  {
    type: SceneType.Animation, changeSceneBorder: false,
    sceneFunction: chooseEmmaHandlerScene4,
    analyticsInteraction: "raviandemma:route:ChooseRaviorEmma"
  },
  {
    type: SceneType.Animation, changeSceneBorder: false,
    sceneFunction: chooseRaviHandlerScene4,
    analyticsInteraction: "raviandemma:route:ChooseRaviorEmma"
  },
]

const scenePlayer = (
  sceneFunction: SceneFunction = null,
  character: Character = null,
  chapterLabel: number = null
): SceneFunction => {
  previousStoryScene = currentStoryScene;
  if (sceneFunction === null) {
    // advance story
    currentStoryScene = getNextSceneStory();
  } else {
    // go to specific function
    currentStoryScene = getStoryScene(sceneFunction, character, chapterLabel);
  }
  if (!currentStoryScene || !(currentStoryScene.sceneFunction)) return landingBit1;
  if (currentStoryScene.analyticsInteraction) fireAnalyticsEvent(currentStoryScene.analyticsInteraction);
  console.log("scenePlayer", currentStoryScene.chapterPart ? currentStoryScene.chapterPart : "", currentStoryScene);
  currentStoryScene.visited = true
  return currentStoryScene.sceneFunction;
}

const getStoryScene = (
  sceneFunction: SceneFunction,
  character: Character = null,
  chapterLabel: number = null,
) => {
  let storyScene: SceneSummary;
  for (let storyIndex in storyScenes) {
    storyScene = storyScenes[storyIndex];
    if (storyScene.sceneFunction == sceneFunction) {
      if ((chapterLabel === null) || (character == null)) {
        return storyScene;
      } else if ((storyScene.chapterLabel == chapterLabel) && (storyScene.character == character)) {
        return storyScene;
      }
    }
  }
}

const setUpMouseButtonInteractions = (button: HTMLElement, hoverLottiePath: string, clickLottiePath: string) => {
  Helper.show(buttonMouseoverAnimation);
  Helper.show(buttonMousedownAnimation);
  Helper.show(button);
  button.onmouseover = (event: Event) => {
    currentScene.buttonMouseoverAnimation = Helper.loadAnimation(bodymovin, buttonMouseoverAnimation.id, hoverLottiePath, true, true, null, null, mainSpeed);
  };
  button.onmouseout = (event: Event) => {
    if (currentScene.buttonMouseoverAnimation) currentScene.buttonMouseoverAnimation.destroy();
    if (currentScene.buttonMousedownAnimation) currentScene.buttonMousedownAnimation.destroy();
  };
  button.onmousedown = (event: Event) => {
    currentScene.buttonMousedownAnimation = Helper.loadAnimation(bodymovin, buttonMousedownAnimation.id, clickLottiePath, true, true, null, null, mainSpeed);
  };
  button.onmouseup = (event: Event) => {
    if (currentScene.buttonMouseoverAnimation) currentScene.buttonMouseoverAnimation.destroy();
    if (currentScene.buttonMousedownAnimation) currentScene.buttonMousedownAnimation.destroy();
  };
}

const breakDownMouseButtonInteractions = (button: HTMLElement) => {
  Helper.hide(buttonMouseoverAnimation);
  Helper.hide(buttonMousedownAnimation);
  button.onmouseover = null;
  button.onmouseout = null;
  button.onmousedown = null;
  button.onmouseup = null;
  if (previousScene && previousScene.buttonMouseoverAnimation) previousScene.buttonMouseoverAnimation.destroy();
  if (previousScene && previousScene.buttonMousedownAnimation) previousScene.buttonMousedownAnimation.destroy();
  Helper.hide(button);
}

const initialiseVariables = () => {
  helpShowCamera.addEventListener("click", function() {
    videoPerms(false)
    showWebcamViewer()
    openWebcamViewer.addEventListener("click", showWebcamViewer);
    closeWebcamViewer.addEventListener("click", hideWebcamViewer);
  })
}

const dragElement = (elmnt: any) => {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

  const dragMouseDown = (e: any) => {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  elmnt.onmousedown = dragMouseDown;

  const elementDrag = (e: any) => {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  const closeDragElement = () => {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  }
}
