import {mainAnimElem1, mainAnimElem2, SceneType, video1,
  video2} from "./index";
import {Helper} from "./helper";
import {Chapter} from "./chapter";

export class Scene {
  type: SceneType;
  elem: HTMLElement;
  mainAnim: any;
  switchAnim: any;
  rewardAnim: any;
  rewardSignAnims: any[] = [];
  rewardSignRolloverAnimations: any[] = [];
  practiceAnim: any;
  practiceRewardAnim: any;
  buttonMouseoverAnimation: any;
  buttonMousedownAnimation: any;
  chapter: Chapter;
  exitFns: any[] = [];

  constructor(
    type: SceneType,
    prevScene: Scene
  ) {
    this.type = type;

    if (this.type == SceneType.Animation) {
      if (!prevScene) {
        this.elem = mainAnimElem1;
      } else {
        if (prevScene.elem == mainAnimElem1) {
          this.elem = mainAnimElem2;
        } else {
          this.elem = mainAnimElem1;
        }
      }
    } else {
      if (!prevScene) {
        this.elem = video1;
      } else {
        if (prevScene.elem == video1) {
          this.elem = video2;
        } else {
          this.elem = video1;
        }
      }
    }
  }

  public setChapter = (chapter: Chapter) => {
    this.chapter = chapter;
  }

  public destroy = () => {
    for (let fn of this.exitFns) {
      fn();
    }
    if (this.mainAnim) this.mainAnim.destroy();
    if (this.switchAnim) this.switchAnim.destroy();
    if (this.rewardAnim) this.rewardAnim.destroy();
    for (let idx in this.rewardSignAnims) {
      if (this.rewardSignAnims[idx]) this.rewardSignAnims[idx].destroy();
    }
    if (this.practiceRewardAnim) this.practiceRewardAnim.destroy();
    if (this.practiceAnim) this.practiceAnim.destroy();
    for (let idx in this.rewardSignRolloverAnimations) {
      if (this.rewardSignRolloverAnimations[idx]) this.rewardSignRolloverAnimations[idx].destroy();
    }
  }

  public pause = () => {
    if (this.type == SceneType.Animation) {
      this.mainAnim.pause();
    } else {
      if (this.chapter) this.chapter.pause();
    }
  }

  public resume = () => {
    if (this.type == SceneType.Animation) {
      this.mainAnim.play();
    } else {
      if (this.chapter) this.chapter.resume();
    }
  }
}
