export class Analytics {
  digitalData: {[index: string]: any};
  userInfo: {[index: string]: any};

  constructor() {
    this.digitalData = this.getDigitalData();
  }

  getUserInfo = (): any => {
    if (window.localStorage.getItem('janrainCaptureReturnExperienceData')) {
      let userInfo = JSON.parse(window.localStorage.getItem('janrainCaptureReturnExperienceData'));
      if (userInfo.uuid) {
        return {
          "userID": userInfo.uuid,
          "syncUserID": [],
          "loginStatus": "login"
        }
      }
    }
    return {
      "userID": "",
      "syncUserID": [],
      "loginStatus": "anonymous"
    }
  };

  getPageInfo = (): any => {
    return {
      "pageName": "s:specials:raviandemma",
      "pageID": "",
      "language": "en",
      "destinationURL": "https://www.sbs.com.au/raviandemma",
      "referralURL": "",
      "originalReferrerURL": "",
      "previousPageURL": "",
      "previousPageName": "",
      "title": "ravi and emma",
      "siteName": "",
      "domain": "www.sbs.com.au",
      "path": "",
      "clickSource": "",
      "campaign": {
        "trackingCode": ""
      }
    };
  }

  getEventTemplate = (): any => {
    return {
      "event": "userInteraction",
      "eventAttribute": {
        "action": "route",
        "siteName": "ravi and emma",
        "interactionName": "",
        "additionalInfo": {
          "page": this.getPageInfo(),
          "user": this.getUserInfo()
        }
      }
    };
  };

  public getDigitalData = (): any => {
    return {
      "page": {
        "pageInfo": this.getPageInfo(),
        "category": {
          "primaryCategory": "specials",
          "siteSubSection1": "ravi and emma",
          "siteSubSection2": "",
          "siteSubSection3": "",
          "siteSubSection4": ""
        },
        "attributes": {
          "content": {
            "contentType" :"Creative Content",
            "title": "Ravi and Emma"},
            "error": {}
        }
      },
      "events": [],
      "user": {
        "userInfo": this.getUserInfo()
      },
      "environment": {
        "browser": {
          "userAgent": navigator.userAgent,
          "version": ""
        },
        "platform": {
          "siteName": "raviandemma",
          "platformType": "web",
          "platformName": "web"
        }
      }
    }
  }

  public addEventToDataLayer = (interactionName: string): void => {
    let event: {[index: string]:any} = this.getEventTemplate();
    event["eventAttribute"]["interactionName"] = interactionName;
    this.digitalData["events"].push(event);
  }
}
