export class DelegatedEventTarget extends EventTarget {
  private delegate = document.createDocumentFragment();
  sendEvents = true

  addEventListener(...args: any): void {
    this.delegate.addEventListener.apply(this.delegate, args);
  }

  dispatchEvent(...args: any): boolean {
    if (this.sendEvents == true) return this.delegate.dispatchEvent.apply(this.delegate, args);
  }

  removeEventListener(...args: any): void {
    return this.delegate.removeEventListener.apply(this.delegate, args);
  }
}
