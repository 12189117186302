import {audioPaths, sceneAudioVolumes, mainSpeed} from "./index";
import {Helper} from "./helper";
import EventTarget from "events";
import {DelegatedEventTarget} from "./delegated_event_target"

export class AudioPlayer extends DelegatedEventTarget {
  audio: HTMLAudioElement;
  audioIsPlaying: boolean = false;
  name: string;
  volume: number;

  constructor() {
    super();
    this.audio = new Audio();
  }

  public setAndPlayAudio = (name: string, fade: boolean = true) => {
    this.name = name;
    if (fade) {
      if (this.audioIsPlaying) {
        this.addEventListener("audio-faded", this.handleAudioFaded);
        this.fadeAndStopAudio(0);
      } else {
        this.handleAudioFaded(null);
      }
    } else {
      this.handleAudioFaded(null);
    }
  }

  public loadAudio = (name: string) => {
    this.name = name;
    this.audio.src = audioPaths[this.name];
  }

  public play = () => {
    this.audio.playbackRate = mainSpeed;
    this.audio.volume = Helper.getPlaybackVolume(this.name);
    this.audio.addEventListener("ended", this.audioEndedHandler);
    this.audio.play();
    this.audioIsPlaying = true;
  }

  audioEndedHandler = (event: Event) => {
    this.audioIsPlaying = false;
  }

  handleAudioFaded = (customEvent: CustomEvent) => {
    this.audio.src = audioPaths[this.name];
    this.audio.addEventListener("canplaythrough", this.audioLoadedHandler);
  }

  audioLoadedHandler = () => {
    this.audio.removeEventListener("canplaythrough", this.audioLoadedHandler);
    this.play();
  }

  public fadeAndStopAudio = (destVol: number) => {
    if (this.audio) {
      this.adjustVolume((this.audio as HTMLMediaElement), destVol).then(() => {
        this.pause(this.audio);
        this.dispatchEvent(
          new CustomEvent("audio-faded")
        )
      });
    }
  }

  public pause = (audio: HTMLAudioElement) => {
    if (audio) {
      audio.pause();
      this.audioIsPlaying = false;
    }
  }

  adjustVolume = (
    element: HTMLMediaElement,
    newVolume: number,
    {
      duration = 1000,
      easing = this.swing,
      interval = 13,
    } = {},
  ): Promise<void> => {
    const originalVolume = element.volume;
    const delta = newVolume - originalVolume;

    if (!delta || !duration || !easing || !interval) {
      element.volume = newVolume;
      return Promise.resolve();
    }

    const ticks = Math.floor(duration / interval);
    let tick = 1;

    return new Promise(resolve => {
      const timer = setInterval(() => {
        element.volume = originalVolume + (
          easing(tick / ticks) * delta
        );

        if (++tick === ticks + 1) {
          clearInterval(timer);
          resolve();
        }
      }, interval);
    });
  }

  swing = (p: number) => {
    return 0.5 - Math.cos(p * Math.PI) / 2;
  }
}
