import {Helper} from "./helper";
import EventTarget from "events";
import {DelegatedEventTarget} from "./delegated_event_target";
import {anims, AnimType, Character,
  mainAudioPlayer, mainSpeed, playAudioAndVideo, sceneElement, SceneSummary,
  videos} from "./index";
import {AudioPlayer} from "./audio_player";
import {Scene} from "./scene";
import {VideoWatcher} from "./video_watcher";

export class Chapter extends DelegatedEventTarget {
  chapterLabel: number;
  scene: Scene;
  videoWatcher: VideoWatcher;
  src: string;
  video: HTMLElement;
  videoAsHTMLVideoElement: HTMLVideoElement;
  char: Character;
  videoTime?: number;

  constructor(
    char: Character,
    storyScene: SceneSummary,
    scene: Scene,
    videoWatcher: VideoWatcher
  ) {
    super();

    this.char = char;
    this.chapterLabel = storyScene.chapterLabel;
    this.scene = scene;
    this.videoTime = storyScene.videoTime;
    this.videoWatcher = videoWatcher;
    this.video = scene.elem;
    this.videoAsHTMLVideoElement = this.video as HTMLVideoElement;
  }

  public play = async () => {
    this.videoAsHTMLVideoElement.src = videos[this.char][this.chapterLabel];
    this.videoAsHTMLVideoElement.load();
    console.log("Loaded video: ", videos[this.char][this.chapterLabel]);
    if (this.videoTime){
      this.videoWatcher.setCurrentTime(this.videoTime);
    }
    this.videoAsHTMLVideoElement.playbackRate = mainSpeed;
    let audioName: string = `${Character[this.char]}-chapter${this.chapterLabel}`
    try {
      await this.videoAsHTMLVideoElement.play();
      mainAudioPlayer.setAndPlayAudio(audioName, false);
      this.video.addEventListener("ended", this.videoEnded);
//      this.pause();
    } catch (error) {
      // do nothing, we assume pause() was called so it's ok to not play
      console.log("Error caught in Chapter.play(): ", error)
    }
  }

  public pause = () => {
    this.videoAsHTMLVideoElement.pause();
  }

  public resume = () => {
    this.videoAsHTMLVideoElement.play();
  }

  public stop = () => {
    this.pause();
    mainAudioPlayer.fadeAndStopAudio(0);
  }

  videoEnded = (event: Event) => {
    this.video.removeEventListener("ended", this.videoEnded);
    this.dispatchEvent(
      new CustomEvent("ended")
    );
  }
}
